// src/components/InvestmentDetails.tsx
import React from 'react';
import { ClickingTimer } from '../../../components/Fields/ClickingTime';
import { UserPaymentPersonalDetails } from '../types';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { Button, Skeleton } from '@mui/material';
import { getRupeeWithDecimal } from '../../../utils/numbers';
import { useMobileSize } from '../../../hooks/useScreenSize';

interface PersonalDetailsProps extends UserPaymentPersonalDetails {
  loading: boolean;
  setShowLenderOnboardingAgreement: (value: boolean) => void;
  makeInvestment: () => void;
  setPhone: (phone: string) => void;
}

// Function to convert time to milliseconds
const convertEpochToLocalMilliseconds = (time: string) => {
  const userTime = new Date().getTime();
  return parseInt(time) - userTime;
};

const WalletDetails: React.FC<PersonalDetailsProps> = ({
  data,
  loading,
  setShowLenderOnboardingAgreement,
  makeInvestment,
  setPhone,
}) => {
  const isMobile = useMobileSize();
  const buttonStyles = {
    marginTop: '20px',
    borderRadius: '12px',
    border: '1px solid #15803D',
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A',
    boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
    color: 'white',
    width: '100%',
    padding: '8px 16px',
    transition: 'background 0.3s ease-in-out, transform 0.3s ease-in-out',
    cursor: 'pointer',
  };

  return (
    <div
      className={`bg-white rounded-lg p-5 ${isMobile && 'mb-5'}`}
      style={{
        minWidth: '450px',
        maxWidth: '800px',
        boxShadow:
          '0px 64px 18px 0px rgba(0, 0, 0, 0.00), 0px 41px 16px 0px rgba(0, 0, 0, 0.01), 0px 23px 14px 0px rgba(0, 0, 0, 0.02), 0px 10px 10px 0px rgba(0, 0, 0, 0.03), 0px 3px 6px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      <div className="flex justify-between items-center">
        <div className="flex ">
          <SubdirectoryArrowRightRoundedIcon
            className="text-stone-400"
            sx={{ width: '15px', height: '15px' }}
          />
          <p className="text-xs text-stone-400 font-medium">
            Requested by {loading ? <Skeleton width={60} /> : data?.partnerName}
          </p>
        </div>
        {loading ? (
          <Skeleton width={60} />
        ) : (
          <ClickingTimer
            time={convertEpochToLocalMilliseconds(data?.expiryDateTime) || 0}
            sx="text-xs text-stone-600 bg-stone-200 px-2 py-1 rounded-2xl"
          />
        )}
      </div>
      <div className="flex flex-col items-center mt-16 gap-1 mb-10">
        <h3 className="text-lg font-bold">Tap Balance Recharge</h3>
      </div>
      <div className="mt-4 flex flex-col gap-4 px-3">
        <div className="flex justify-between">
          <p className="text-base text-stone-500">Wallet Balance: </p>
          <p className="text-base font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              getRupeeWithDecimal.format(data?.walletBalance || 0)
            )}
          </p>
        </div>
      </div>
      <div
        className="border-gray-300 mt-4 pt-4"
        style={{
          backgroundSize: '24px 1px',
          backgroundRepeat: 'repeat-x',
          backgroundImage:
            'linear-gradient(to right, rgba(0,0,0,0.12) 35%, rgba(255,255,255,0) 0%)',
        }}
      >
        <div className="flex justify-between px-3">
          <p className="text-base text-stone-500">To be paid:</p>
          <p className="text-base font-semibold text-green-700">
            {loading ? <Skeleton width={60} /> : getRupeeWithDecimal.format(data?.amount || 0)}
          </p>
        </div>

        <Button
          style={buttonStyles}
          onMouseDown={(e) => {
            e.currentTarget.style.background = '#15803D';
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.background =
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A';
          }}
          onClick={() => {
            makeInvestment();
          }}
        >
          pay now
        </Button>
      </div>
    </div>
  );
};

export default WalletDetails;
