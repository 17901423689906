import React from 'react';

export const MULTI_FAQS = [
  {
    id: 0,
    question: 'What is Multi?',
    answer:
      'Multi is an investment product designed to offer diversification and reduce risk for investors. It allows you to invest in a pre-selected pool of invoices from a variety of companies instead of focusing on a single issuer. This diversification approach helps mitigate the potential impact of a single company defaulting on its invoice payments.',
  },
  {
    id: 1,
    question: 'How does Multi work?',
    answer: (
      <div>
        <p>
          Multi leverages a concept called Invoice Pooling to offer you a diversified investment
          opportunity. Here's a breakdown of the process:
        </p>
        <ol className="list-decimal pl-6 my-3">
          <li>
            <b>Companies Submit Invoices:</b> Businesses with outstanding invoices from creditworthy
            customers can submit them to Tap.
          </li>
          <li>
            <b>Invoice Aggregation:</b> We source invoices and create a pool by aggregating invoices
            from over 10 different companies. This pool represents a diversified mix of industries
            and credit profiles.
          </li>
          <li>
            <b>Investment Opportunity:</b> As an investor, you can then choose to invest in this
            Multi pool. Your investment goes towards the collective value of the invoices within the
            pool.
          </li>
          <li>
            <b>Payments and Returns:</b> When a company in the pool pays their invoice, the funds
            are collected and distributed proportionally to investors in the Multi. Your return is
            based on the overall performance of the invoices within the pool.
          </li>
        </ol>
        <p className="mb-3">There is one key benefit of Invoice Pooling for Investors:</p>
        <p>
          <b>Diversification:</b> By investing in a pool of invoices from multiple companies, you
          spread your investment risk. This means that if one company defaults on its invoice, the
          impact on your overall investment is potentially lessened compared to investing in a
          single invoice.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    question: 'What are the benefits of Multi?',
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          <b>Diversification:</b> Spread your investment across multiple companies, reducing risk
          from a single company default.
        </p>
        <p>
          <b>Reduced Risk:</b> Lower potential impact of defaults compared to single-issuer
          invoices.
        </p>
        <p>
          <b>Streamlined Investment:</b> Invest in a pre-selected pool, simplifying the selection
          process.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    question: 'What happens if a company in the pool defaults or delays payment?',
    answer: (
      <div>
        <p>
          Tap prioritises investor protection and utilises a robust collections system modelled
          after industry best practices:
        </p>

        <ol className="list-decimal pl-6 my-3">
          <li>
            <b>Default Mitigation:</b> We employ rigorous due diligence when selecting invoices.
            Additionally, post-dated checks (PDCs) and e-NACH mandates are obtained upfront to
            secure timely payments and minimise defaults.
          </li>
          <li>
            <b>Delinquency Management:</b> Our collections system employs a multi-tiered approach to
            address payment delays. This includes early intervention through automated reminders,
            followed by escalating communication channels with delinquent companies, a strong
            collections & recovery task force to streamline collections.
          </li>
          <li>
            <b>Legal Action:</b> If necessary, Tap will pursue legal action in accordance with
            Karnataka state regulations to recover outstanding funds. We leverage experienced
            recovery specialists to maximise the potential for full repayment.
          </li>
        </ol>
        <p>
          While our comprehensive collections system aims to mitigate risk, it's important to
          understand that all investments carry inherent risks.{' '}
        </p>
      </div>
    ),
  },
  {
    id: 4,
    question: 'Can I exit the investment in Multi before the tenure ends?',
    answer:
      'No, early exits are currently unavailable for Multi ID investments. These pools are designed as short-term investments with a predetermined duration specified at the time of investment. This structure allows for optimized management and collection of the entire pool, ultimately aiming to maximize potential returns for investors.',
  },
  {
    id: 5,
    question: 'How is investor money protected in Invoice Pooling?',
    answer: (
      <div>
        <p>
          Multi prioritises investor protection by employing a multi-layered security framework:
        </p>
        <ol className="list-decimal pl-6 my-3">
          <li>
            <b>Legal Safeguards:</b> Ironclad contracts govern the rights and obligations of all
            parties involved. These contracts ensure adherence to terms and provide legal recourse
            in case of disputes.
          </li>
          <li>
            <b>Upfront Security:</b> Multi requires post-dated checks (PDCs) and e-NACH mandates
            from invoice issuers before including invoices in a pool to guarantee timely payments
            and minimise defaults. These instruments act as upfront security measures.
          </li>
          <li>
            <b>Automated Collections:</b> A robust and automated payout collection process ensures
            efficient retrieval of funds once invoices mature.
          </li>
          <li>
            <b>Diversification Strategy:</b> By spreading investments across multiple companies
            within a single pool, Multi mitigates the impact of a potential default from any one
            company. This diversification helps reduce overall investment risk.
          </li>
        </ol>
      </div>
    ),
  },
  {
    id: 6,
    question: 'What is the typical duration of an investment in Multi?',
    answer: (
      <div>
        <p className="mb-3">
          Multi is designed to be short-term, offering flexibility for your capital. The specific
          investment duration varies depending on the underlying invoices within the pool. However,
          you can typically expect a timeframe ranging from 30 to 90 days.
        </p>
        <p className="mb-3">
          <b>Knowing the investment term:</b> The exact duration of each Multi will be clearly
          communicated before you invest. This transparency allows you to make informed decisions
          that align with your financial goals and investment timeline.
        </p>
        <p className="mb-2">
          <b>Benefits of Short-Term Investments:</b>
        </p>
        <p className="mb-2">
          <b>Increased Liquidity:</b> Short-term investments offer faster access to your capital
          compared to long-term commitments.
        </p>
        <p>
          <b>Reduced Market Volatility:</b> Shorter durations potentially lessen the impact of
          market fluctuations on your investment.
        </p>
        <p className="mt-3">
          We recommend carefully reviewing the investment details, including the specific term
          length, before committing to a Multi.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    question: "What is Multi's investment strategy?",
    answer: (
      <div className="flex flex-col gap-3">
        <p>
          Multi utilizes a strategy called invoice pooling to offer investors exposure to a
          diversified pool of short-term corporate invoices. Here's the breakdown:
        </p>
        <p>
          <b>Diversification:</b> Multi pools invoices from over 10 different companies, spreading
          your investment risk across various industries and credit profiles.
        </p>
        <p>
          <b>Short-Term Focus:</b> These invoice pools are designed as short-term investments,
          typically lasting between 30 and 90 days. This provides access to potentially faster
          returns and increased liquidity compared to long-term investments.
        </p>
      </div>
    ),
  },
];

export const IDFaq = [
  {
    id: 0,
    question: 'What is Invoice Discounting?',
    answer: (
      <div className="flex flex-col gap-3">
        <p>
          Invoice discounting, also referred to as invoice financing, is a process by which
          businesses borrow money for their short-term needs against the money which they will
          receive from their customers in the future.
        </p>
        <p>The following example helps to illustrate the flow of funds:</p>
        <p>
          Assume a business manufactures batteries that it then sells to automobile companies. When
          the business sells its products, in this case batteries, it raises an invoice to the
          customer. Based on the business’ understanding with its client, it will get paid ninety
          (90) days after delivery of the batteries to the client. The business’ capital is blocked
          until the client pays cash to the business – its cash went to purchase materials to build
          batteries, then it sold those batteries to its client, but it won’t get paid for those
          batteries until ninety (90) days later! Significant amounts of cash can get tied up in
          working capital.
        </p>
        <p>
          If the business needs to start preparing for the next batch of batteries to sell to its
          client, and it requires additional cash rather than wait to be paid, it can approach a
          financing company willing to provide capital to bridge from the time of invoicing till the
          time of receipt of capital from its customer.
        </p>
        <p>
          Invoice discounting thus works in this way – a company financing invoice discounting is
          financing the receivables of companies.
        </p>
        <p>
          <strong>Tap Invest</strong> enables you, our customer, to finance the receivables of such
          companies and earn interest on those receivables.
        </p>
        <p>
          For any additional queries, feel free to e-mail us at the following e-mail address:
          support@tapinvest.in.
        </p>
      </div>
    ),
  },
  {
    id: 1,
    question: 'How does invoice discounting work on Tap Invest?',
    answer: (
      <div className="flex flex-col gap-3">
        <p>Invoice Discounting on Tap Invest is a very seamless and intuitive process.</p>
        <p>
          Once you, Tap Investor's customer, sign up on the platform, you will see opportunities to
          invest in, located in the section on the website showing our live transactions.
        </p>
        <p>
          Invoice Discounting opportunities are identifiable by the deal card, or they can be
          highlighted by selecting the invoice discount filter option. The companies where we offer
          opportunities to invest are selected after completion of a rigorous risk analysis and due
          diligence by the in-house Tap Invest Investments Team. Customers of Tap Invest, also known
          as “Investors,” can provide capital through the Tap Invest platform to companies that
          require working capital loans. These loans are provided against receivables with real
          invoices.
        </p>
        <p>As a customer and Investor, to get started, you need to do the following:</p>
        <ol>
          <li>Sign up on the platform and complete the KYC (Know-Your-Customer) process</li>
          <li>
            Select the company you wish to purchase a part of invoice receivables from the deals
            section.
          </li>
          <li>Complete the payment via any of the methods offered</li>
          <li>
            Once the tenure of the investment is completed, you will receive the entire amount you
            invested along with expected returns
          </li>
        </ol>
        <p>
          For any additional queries, feel free to e-mail us at the following e-mail address:
          support@tapinvest.in.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    question:
      'How do I get my money back after I participate in an Invoice Discounting opportunity?',
    answer: (
      <div className="flex flex-col gap-3">
        <p>
          Once the company gets paid by its customer within the set tenure, it will return the
          borrowed money to you, the Tap Invest customer, with interest. The funds will be deposited
          into the bank account you provided during the KYC process.
        </p>
        <p>
          For any additional queries, feel free to e-mail us at the following e-mail address:
          support@tapinvest.in.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    question: 'What is the structure or instrument of Invoice Discounting?',
    answer: (
      <div className="flex flex-col gap-3">
        <p>
          The Investor gets the right to receive payment from the invoice that the seller had raised
          to its customer. There is no entity involved in between the Investor and the Financing
          Recipient – i.e money flows directly between the Investor and the Financing Recipient.
        </p>
        <p>Because there is no pooling of capital, no structure is required.</p>
        <p>
          For any additional queries, feel free to e-mail us at the following e-mail address:
          support@tapinvest.in.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    question: 'Can I exit the investment in Invoice Discounting before the tenure ends?',
    answer: (
      <div className="flex flex-col gap-3">
        <p>
          These investment transactions have shorter tenures. That being said, there are no
          provisions to provide early exits to investors.
        </p>
        <p>
          For any additional queries, feel free to e-mail us at the following e-mail address:
          support@tapinvest.in.
        </p>
      </div>
    ),
  },
];

export const DEAL_DETAIL_EVENTS = {
  Deal_Detail_Page: 'Deal_Detail_Page',
  Deal_Detail_Page_View: 'Deal_Detail_Page_View',
  Deal_Detail_Client_Investment_Search: 'Deal_Detail_Client_Investment_Search',
  Deal_Detail_Client_Select: 'Deal_Detail_Client_Select',
  Deal_Detail_Wallet_Details: 'Deal_Detail_Wallet_Details',
  Deal_Detail_Agreement_Confirm: 'Deal_Detail_Agreement_Confirm',
  Investment_Success_Screen: 'Investment_Success_Screen',
  Deal_Detail_Generate_Payment_Link: 'Deal_Detail_Generate_Payment_Link',
};
