import React, { useEffect, useState } from 'react';
import { NetworkManager } from '../../services';
import { CloseOutlinedIcon } from '../../assets/mui';

interface UploadStatusProps {
  bulkUploadTriggered: boolean;
  setBulkUploadTriggered: (value: boolean) => void;
}

const UploadStatus: React.FC<UploadStatusProps> = ({
  bulkUploadTriggered,
  setBulkUploadTriggered,
}) => {
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Initially hidden until data is fetched
  const [shouldPoll, setShouldPoll] = useState(false); // Start polling only when conditions are met
  const [uploadId, setUploadId] = useState(localStorage.getItem('uploadId') || '');
  const [authToken] = useState(localStorage.getItem('token') || ''); // only call API if token is present
  const [retryCount, setRetryCount] = useState(0);

  // Trigger polling when bulk upload is initiated or uploadId changes
  useEffect(() => {
    const storedUploadId = localStorage.getItem('uploadId');
    if (storedUploadId) {
      setUploadId(storedUploadId);
      setShouldPoll(true);
    }
  }, [bulkUploadTriggered]);

  // Listen for changes in localStorage for uploadId from other tabs
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'uploadId') {
        setUploadId(event.newValue || '');
        setShouldPoll(!!event.newValue); // Start polling if uploadId is added, stop if removed
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Polling logic: fetch upload status when uploadId is present and shouldPoll is true
  useEffect(() => {
    if (!authToken) return;
    if (!uploadId || !shouldPoll) return;

    const fetchUploadStatus = async () => {
      try {
        const response = await NetworkManager.Call({
          method: 'GET',
          path: NetworkManager.PATH.bulkUploadStatus.replace('{{uploadId}}', uploadId),
        });

        if (response) {
          setUploadStatus(response);
          setIsVisible(true);
          setBulkUploadTriggered(false); // Reset bulkUploadTriggered after fetching status
          // Stop polling if progress is 100
          if (response.downloadUrl) {
            setShouldPoll(false);
          }
        } else {
          // If response is null, stop polling
          setBulkUploadTriggered(false);
          setShouldPoll(false);
        }
      } catch (error) {
        console.error('Error fetching upload status:', error);
        const status = error.response?.status;
        if ((status >= 400 && status < 500) || (status >= 500 && status < 600)) {
          if (retryCount >= 2) {
            // Stop polling after 3 failed attempts
            setShouldPoll(false);
            setBulkUploadTriggered(false);
            setRetryCount(0); // Reset retry count
            localStorage.removeItem('uploadId'); // Remove uploadId from local storage
          } else {
            setRetryCount((prev) => prev + 1);
          }
        } else {
          // For other errors, stop polling
          setShouldPoll(false);
          setBulkUploadTriggered(false);
        }
      }
    };

    // Immediately fetch status, then set up interval for continuous polling
    fetchUploadStatus();
    const intervalId = setInterval(fetchUploadStatus, 1000);

    // Clear interval on component unmount or when polling stops
    return () => clearInterval(intervalId);
  }, [uploadId, shouldPoll, authToken, retryCount]);

  const handleClose = () => {
    setIsVisible(false);
    setShouldPoll(false);
    setBulkUploadTriggered(false);
    setUploadStatus(null);
    localStorage.removeItem('uploadId');
  };

  if (!uploadStatus || !isVisible) return null;

  return (
    <div className="fixed top-5 right-5 bg-white p-4 rounded-lg border border-gray-300 shadow-lg z-[100] w-[290px] flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h4 className="text-lg font-semibold text-gray-800 mr-4">
          {uploadStatus.progress !== 100 ? 'Uploading...' : 'Upload Complete'}
        </h4>
        <CloseOutlinedIcon
          className="cursor-pointer bg-gray-200 rounded-full p-1 hover:bg-gray-300"
          onClick={handleClose}
          style={{
            width: '28px',
            height: '28px',
          }}
        />
      </div>

      <div className="text-center text-gray-700">
        <p>
          Total Items: <span className="font-semibold">{uploadStatus.totalItems}</span>
        </p>
        <div className="flex justify-around mt-2">
          <p className="text-green-600 font-semibold">{uploadStatus.successfulItems} Success</p>
          <span className="text-gray-400">|</span>
          <p className="text-red-600 font-semibold">{uploadStatus.failedItems} Failure</p>
        </div>
        <p className="mt-2">Progress: {uploadStatus.progress}%</p>
      </div>

      {uploadStatus.downloadUrl ? (
        <a
          href={uploadStatus.downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-700 flex justify-center items-center gap-2 text-sm text-white rounded-lg font-semibold h-10 px-4 hover:bg-green-800 transition-colors disabled:bg-green-700/60"
        >
          Download CSV to find out more
        </a>
      ) : (
        <div className="bg-green-700 flex justify-center items-center gap-2 text-sm text-white rounded-lg font-semibold h-10 px-4 transition-colors bg-green-700/60 cursor-not-allowed">
          generating report...
        </div>
      )}
    </div>
  );
};

export default UploadStatus;
