import React, { useState } from 'react';
import Input from '../../components/Fields/Input';
import { Field } from '../../components/Fields/Field';
import OTPWrapper from './OTPWrapper';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NetworkManager } from '../../services';
import { useOnboardingNavigate } from './store';
import { toast } from 'react-hot-toast';
import { kycError } from '../../utils/toast';
import KYCButton from './KYCButton';
import { AADHAAR_ERROR_MESSAGE, ONBOARDING_EVENTS } from './constants';
import { useAnalytics } from '../../hooks/useAnalytics';
import AadhaarOCR from './AadhaarOCR';
import { ArrowForwardIosIcon, FilePresentIcon } from '../../assets/mui';

interface AadhaarVerificationProps {}

const aadhaarSchema = yup
  .string()
  .required('Please enter Aadhaar Number')
  .test('no-spaces', 'Aadhaar number should not contain spaces', (value) => {
    return !/\s/.test(value);
  })
  .matches(/^[0-9]{12}$/, 'Invalid Aadhaar number format');

const incorrectOtp = () =>
  toast.error('Invalid OTP', {
    duration: 2500,
    position: 'top-center',
  });

const AadhaarVerification: React.FC<AadhaarVerificationProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyStep, setVerifyStep] = useState(false);
  const { handleNextPage } = useOnboardingNavigate();
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [timeForOtpResend, setTimeForOtpResend] = useState(30);
  const [aadhaar, setAadhaar] = useState<string | null>(null);
  const [aadhaarOCRSelected, setAadhaarOCRSelected] = useState<boolean>(false);
  const { track } = useAnalytics();

  const schema = yup.object().shape({
    otp: yup.number().when('aadhaarSaved', ([aadhaarSaved]) => {
      if (aadhaarSaved) {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .max(999999, 'Must be a 6-digit OTP')
          .min(100000, 'Must be a 6-digit OTP')
          .required('Please enter OTP');
      } else {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable();
      }
    }),
    aadhaar: aadhaarSchema,
    aadhaarSaved: yup.bool().default(false),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { aadhaar: null },
  });

  const submitAadhaar = (aadhaar: string) => {
    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.aadhaarInfo.replace('{{aadhaar}}', aadhaar),
    });
  };

  const submitOTP = (aadhaar: string, otp: number) => {
    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.verifyAadhaarInfo,
      data: {
        aadhaar: aadhaar,
        otp: otp,
      },
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { aadhaar, otp } = data;
    setIsLoading(true);
    setAadhaar(aadhaar);
    if (!otp) {
      setTimeForOtpResend(30);
    }

    if (isVerifyStep) {
      try {
        const otpRes = await submitOTP(aadhaar, otp);
        if (!otpRes.error) {
          handleNextPage(otpRes.nextPage);
        } else {
          kycError(AADHAAR_ERROR_MESSAGE);
          setVerifyStep(false);
          setValue('aadhaar', aadhaar);
        }
      } catch (error) {
        if (error?.response?.status?.toString().startsWith('5')) {
          toast.error(AADHAAR_ERROR_MESSAGE, {
            duration: 2500,
            position: 'top-center',
          });
        } else if (error?.response?.status?.toString().startsWith('4')) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: 'top-center',
          });
        } else {
          toast.error(AADHAAR_ERROR_MESSAGE, {
            duration: 2500,
            position: 'top-center',
          });
        }
      }
    } else {
      try {
        const aadhaarRes = await submitAadhaar(aadhaar);
        if (!aadhaarRes.error) {
          setValue('aadhaarSaved', true);
          setValue('otp', null);
          setVerifyStep(true);
          handleEntryEvent(ONBOARDING_EVENTS.KYC_Aadhaar_Request_OTP);
        } else {
          kycError(AADHAAR_ERROR_MESSAGE);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: 'top-center',
          });
        } else {
          toast.error(AADHAAR_ERROR_MESSAGE, {
            duration: 2500,
            position: 'top-center',
          });
        }
      }
    }
    setIsLoading(false);
  };

  const handleEntryEvent = (event) => {
    track({ eventName: event });
  };

  React.useEffect(() => {
    if (timeForOtpResend > 0) {
      const timerId = setTimeout(() => {
        setTimeForOtpResend(timeForOtpResend - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsOtpResend(true);
    }
  }, [timeForOtpResend]);

  const handleResend = async () => {
    setIsOtpResend(true);

    try {
      const otpRes = await submitAadhaar(aadhaar);

      if (otpRes?.code?.toString().startsWith('2')) {
        toast.success('OTP sent successfully', {
          duration: 2500,
          position: 'top-center',
        });
      } else if (otpRes.error.includes('Invalid OTP')) {
        incorrectOtp();
      } else {
        toast.error(AADHAAR_ERROR_MESSAGE, {
          duration: 2500,
          position: 'top-center',
        });
      }
    } catch (err) {
      if (err?.response?.status?.toString().startsWith('4')) {
        toast.error(
          `${err?.response?.data?.message || 'Something went wrong, please try again later'}`,
          {
            duration: 2500,
            position: 'top-center',
          },
        );
      } else {
        toast.error(AADHAAR_ERROR_MESSAGE, {
          duration: 2500,
          position: 'top-center',
        });
      }
    } finally {
      setIsOtpResend(false);
      setTimeForOtpResend(30);
    }
  };

  return (
    <OTPWrapper
      title="Aadhaar Verification"
      verifyTitle="Aadhaar Verification"
      subtitle="Please enter or upload Aadhaar to confirm your address."
      verifySubtitle="Please provide OTP sent to your registered mobile number"
      isVerifyStep={isVerifyStep}
      aadhaarOCRSelected={aadhaarOCRSelected}
      resetVerify={() => {
        setValue('aadhaarSaved', false);
        setValue('aadhaar', null);
        if (aadhaarOCRSelected) {
          setAadhaarOCRSelected(false);
        } else {
          setVerifyStep(false);
        }
        setIsLoading(false);
      }}
    >
      {aadhaarOCRSelected ? (
        <AadhaarOCR
          setAadhaarOCRSelected={setAadhaarOCRSelected}
          isClient={false}
          handleStep={handleNextPage}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {isVerifyStep ? (
            <Field label="OTP" error={errors.otp?.message}>
              <Input
                onClick={() => handleEntryEvent(ONBOARDING_EVENTS.KYC_Aadhaar_OTP_Entry)}
                type="text"
                numericOnly={true}
                placeholder="Enter OTP"
                {...register('otp')}
              />
            </Field>
          ) : (
            <Field label="Aadhaar Number" error={errors.aadhaar?.message}>
              <Input
                onClick={() => handleEntryEvent(ONBOARDING_EVENTS.KYC_Aadhaar_Entry)}
                type="text"
                numericOnly={true}
                placeholder="1234 4567 8797"
                {...register('aadhaar', { required: true, maxLength: 12 })}
              />
            </Field>
          )}
          <KYCButton
            isLoading={isLoading}
            text={isVerifyStep ? 'Confirm & Proceed' : 'Send Verification Code'}
          />
          {isVerifyStep && !aadhaarOCRSelected && (
            <button
              disabled={!isOtpResend}
              type="button"
              onClick={handleResend}
              className={`${
                !isOtpResend ? 'mx-[27%]' : 'mx-[32%]'
              } text-sm px-7 rounded-xl text-black/80  h-10 mt-5 `}
            >
              Resend OTP {!isOtpResend ? `In ${timeForOtpResend}` : ''}
            </button>
          )}
          {!isVerifyStep && !aadhaarOCRSelected && (
            <>
              <div className="flex items-center my-6">
                <div className="border-t flex-grow border-gray-300"></div>
                <span className="mx-4 text-gray-500">OR</span>
                <div className="border-t flex-grow border-gray-300"></div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="aadhaarUpload"
                  className="block text-xs font-medium text-gray-700 mb-2"
                >
                  Upload Aadhaar card
                </label>
                <div
                  className="border border-black/15 rounded-md p-4 flex items-center justify-between cursor-pointer"
                  onClick={() => setAadhaarOCRSelected(true)}
                >
                  <div className="flex items-center gap-3">
                    <FilePresentIcon className="text-stone-500 !w-5 !h-5" />
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="aadhaarUpload"
                        className="cursor-pointer text-stone-600 font-medium text-sm"
                      >
                        Proceed to upload the file
                      </label>
                      <span className="text-stone-500 text-xs font-normal opacity-70">
                        Upload PDF, PNG, or JPEG file up to 2MB
                      </span>
                    </div>
                    <ArrowForwardIosIcon className="text-stone-500 !w-5 !h-5 ml-12" />
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      )}
    </OTPWrapper>
  );
};

AadhaarVerification.displayName = 'AadhaarVerification';

export default AadhaarVerification;
