import dayjs from 'dayjs';

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
}

// Function to get ordinal suffix for a number
function getOrdinalSuffix(number: number) {
  if (number >= 11 && number <= 13) {
    return 'th';
  }
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const getMonthDayDateForCalender = (epochTime: any) => {
  const date = dayjs(epochTime);
  return {
    month: dayjs(date).format('MMM'),
    date: date.date(),
    day: dayjs(date).format('ddd'),
    year: dayjs(date).format('YYYY'),
  };
};
