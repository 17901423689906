import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import TapImgLogo from '../../assets/PTCTap.png';
import { ApartmentRoundedIcon, ArticleOutlinedIcon, HandshakeOutlinedIcon } from '../../assets/mui';

const PartiesInvolved = () => {
  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        background: 'rgba(0, 0, 0, 0.02)',
      }}
    >
      <TableContainer
        sx={{
          width: '100%',
        }}
      >
        <Table>
          <TableHead
            sx={{
              width: '100%',
            }}
          >
            <TableRow
              sx={{
                width: '100%',
                borderBottom: '1px dashed rgba(0, 0, 0, 0.12)',
              }}
            >
              <TableCell
                sx={{
                  borderBottom: '0px',
                  padding: '24px 24px 18px 24px',
                  width: '30%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <Box
                  sx={{
                    padding: '0 16px 0 18px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}
                    className="bg-stone-200"
                  >
                    <ApartmentRoundedIcon />
                  </Box>
                  <Box>
                    <Typography>AP Factors</Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ borderBottom: '0px' }}>
                <Box
                  sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 400 }} className="text-stone-500">
                    Originator and Servicer of trade receivables, responsible for purchasing
                    invoices and managing collections.
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400 }} className="text-stone-500">
                    Provides credit enhancement by offering a 10% First Loss Default Guarantee
                    (FLDG).
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                width: '100%',
                borderBottom: '1px dashed rgba(0, 0, 0, 0.12)',
              }}
            >
              <TableCell
                sx={{
                  borderBottom: '0px',
                  padding: '24px 24px 18px 24px',
                  width: '30%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <Box
                  sx={{
                    padding: '0 16px 0 18px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}
                    className="bg-stone-200"
                  >
                    <ArticleOutlinedIcon />
                  </Box>
                  <Box>
                    <Typography>SPV-ReST</Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ borderBottom: '0px' }}>
                <Box sx={{ padding: '24px' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400 }} className="text-stone-500">
                    Established to hold the trade receivables; issues Pass-Through Certificates
                    (PTCs) to investors and manages the cash flows generated from the underlying
                    assets.
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                width: '100%',
                borderBottom: '1px dashed rgba(0, 0, 0, 0.12)',
              }}
            >
              <TableCell
                sx={{
                  borderBottom: '0px',
                  padding: '24px 24px 18px 24px',
                  width: '30%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <Box
                  sx={{
                    padding: '0 16px 0 18px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}
                    className="bg-stone-200"
                  >
                    <img alt={'tap logo'} src={TapImgLogo} height={24} width={24} />
                  </Box>
                  <Box>
                    <Typography>Tap Invest</Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ borderBottom: '0px' }}>
                <Box sx={{ padding: '24px' }}>
                  <Typography className="text-stone-500" sx={{ fontSize: '14px', fontWeight: 400 }}>
                    Acts as a distributor for the Pass-Through Certificates (PTCs), allowing
                    investors to access and purchase these instruments. The platform provides
                    detailed information about the PTCs, facilitating informed investment decisions.
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                width: '100%',
              }}
            >
              <TableCell
                sx={{
                  borderBottom: '0px',
                  padding: '24px 24px 18px 24px',
                  width: '30%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <Box
                  sx={{
                    padding: '0 16px 0 18px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}
                    className="bg-stone-200"
                  >
                    <HandshakeOutlinedIcon />
                  </Box>
                  <Box>
                    <Typography>Investors</Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ borderBottom: '0px' }}>
                <Box sx={{ padding: '24px' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400 }} className="text-stone-500">
                    Individuals or entities who invest through the platform, they purchase units of
                    the PTCs to gain access in the diversified pool of trade receivables and benefit
                    from the associated returns.
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PartiesInvolved;
