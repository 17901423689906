import React from 'react';

const DealStatus = ({ noOfDays, status }) => {
  const getStatusTag = (value) => {
    if (value > 0) {
      return (
        <div className="bg-green-700/15 w-fit rounded-xl text-green-700 py-1 px-2 text-xs font-semibold">
          Ongoing deal
        </div>
      );
    }
    if (value === 0) {
      return (
        <div className="bg-blue-700/15 w-fit rounded-xl text-blue-700 py-1 px-2 text-xs font-semibold">
          Maturing today
        </div>
      );
    }
    if (value < 0) {
      return (
        <div className="bg-yellow-700/15 w-fit rounded-xl text-yellow-700 py-1 px-2 text-xs font-semibold">
          Closed
        </div>
      );
    }
  };
  const createTagUsingStatus = (status) => {
    if (status === 'SUCCESS') {
      return (
        <div className="bg-green-700/15 w-fit rounded-xl text-green-700 py-1 px-2 text-xs font-semibold">
          Success
        </div>
      );
    }
    if (status === 'PENDING') {
      return (
        <div className="bg-yellow-700/15 w-fit rounded-xl text-yellow-700 py-1 px-2 text-xs font-semibold">
          Pending
        </div>
      );
    }
    if(status === 'MATURING') {
      return (
        <div className="bg-blue-700/15 w-fit rounded-xl text-blue-700 py-1 px-2 text-xs font-semibold">
          Maturing
        </div>
      );
    }
  };
  return <div>{createTagUsingStatus(status)}</div>;
};

export default DealStatus;
