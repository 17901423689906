import React, { useState } from 'react';
import { Avatar, Skeleton } from '@mui/material';
import useGetPartnerProfile from '../../hooks/useGetPartnerProfile';
import Tables from '../../components/organisms/Tables/Tables';
import { useQuery } from 'react-query';
import { getProfileInvoices, downloadProfileInvoices } from '../../hooks/usePartnerConfig';
import CurrencyAmount from '../../components/organisms/Tables/CellRenderer/CurrencyAmount';
import toast from 'react-hot-toast';
import { useProfile } from '../../context/ProfileContext';

interface ProfileProps {}

const columns = [
  // {
  //   id: 'invoiceName',
  //   label: 'Invoice Name',
  //   minWidth: 100,
  //   align: 'left',
  // },
  {
    id: 'date',
    label: 'Month',
    minWidth: 100,
    align: 'left',
  },
  { id: 'initialAmount', label: 'Gross Amount', minWidth: 100, align: 'left' },
  { id: 'gstPercentage', label: 'GST Amt (GST%)', minWidth: 100, align: 'left' },
  {
    id: 'tdsPercentage',
    label: 'TDS Amt (TDS%)',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'amountPaid',
    label: 'Net Amount',
    minWidth: 100,
    align: 'left',
  },
  { id: 'invoiceNumber', label: 'Invoice Number', minWidth: 170, align: 'left' },
  {
    id: 'signedURL',
    label: 'Download Invoice',
    minWidth: 100,
    align: 'left',
  },
];

const DownloadButton = React.memo(
  ({
    invoiceNumber,
    isLoading,
    onDownload,
  }: {
    invoiceNumber: string;
    isLoading: boolean;
    onDownload: (invoiceNumber: string) => void;
  }) => {
    return (
      <button
        className="border border-green-800 text-sm mx-auto text-green-800 rounded-lg font-semibold py-2 px-1 w-[130px] opacity-80 hover:opacity-100 hover:bg-green-800 hover:text-white transition-all duration-200 ease-in-out"
        onClick={(e) => {
          e.stopPropagation();
          onDownload(invoiceNumber);
        }}
        disabled={isLoading}
      >
        {isLoading ? 'Downloading...' : 'Download'}
      </button>
    );
  },
);

DownloadButton.displayName = 'DownloadButton';

const Profile: React.FC<ProfileProps> = () => {
  const [downloadingInvoices, setDownloadingInvoices] = useState<Record<string, boolean>>({});
  const { data, isLoading: loadingPartnerProfile } = useGetPartnerProfile();

  const { profileData } = useProfile();

  const { profile } = data?.data || {};
  const { accountNumber, accountIfsc } = data?.data?.bankAccount
    ? data?.data?.bankAccount
    : { accountNumber: '', accountIfsc: '' };

  const { firstName, lastName, email, phone, panLinkedAadhaar, pan, gst } = profile || {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    panLinkedAadhaar: '',
    pan: '',
    gst: '',
  };

  let name = `${firstName} ${lastName}`;

  const handleDownload = React.useCallback(async (invoiceNumber: string) => {
    setDownloadingInvoices((prev) => ({ ...prev, [invoiceNumber]: true }));
    try {
      const res = await downloadProfileInvoices({ invoiceId: invoiceNumber });
      const link = document.createElement('a');
      link.href = res;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      const status = error.response?.status;

      if (status && status >= 300 && status < 500) {
        toast.dismiss();
        toast.error(error.response.data.message);
      } else {
        toast.dismiss();
        toast.error('Error downloading invoice');
      }
    } finally {
      setDownloadingInvoices((prev) => ({ ...prev, [invoiceNumber]: false }));
    }
  }, []);

  const getRowsAndCols = (data: any[]) => {
    return data?.map((element) => ({
      date: new Date(element.date).toLocaleString('default', { month: 'long', year: 'numeric' }),
      initialAmount: (
        <CurrencyAmount amount={element.initialAmount} key={`amount-${element.invoiceNumber}`} />
      ),
      gstPercentage: isNaN(parseFloat(((element.initialAmount * element.gstPercentage) / 100).toFixed(2)))
        ? 'N/A'
        : parseFloat(((element.initialAmount * element.gstPercentage) / 100).toFixed(2)) +
          ' (' +
          element.gstPercentage.toFixed(2) +
          '%)',
      tdsPercentage: isNaN(parseFloat(((element.initialAmount * element.tdsPercentage) / 100).toFixed(2)))
        ? 'N/A'
        : parseFloat(((element.initialAmount * element.tdsPercentage) / 100).toFixed(2)) +
          ' (' +
          element.tdsPercentage.toFixed(2) +
          '%)',
      amountPaid: (
        <CurrencyAmount
          amount={element.amountPaid}
          key={`paid-${element.invoiceNumber}`}
          customStyle={{ fontWeight: '600' }}
        />
      ),
      invoiceNumber: element.invoiceNumber,

      signedURL: (
        <DownloadButton
          key={`download-${element.invoiceNumber}`}
          invoiceNumber={element.invoiceNumber}
          isLoading={downloadingInvoices[element.invoiceNumber]}
          onDownload={handleDownload}
        />
      ),
    }));
  };

  const { data: profileInvoices } = useQuery('profileInvoices', getProfileInvoices);
  const rows = getRowsAndCols(profileInvoices || []);

  return (
    <>
      {/* User Details */}
      <div className="my-6 mx-auto">
        {profileData?.features?.enableProfileDetails && (
          <div className="bg-black/[0.04] min-h-20 grid lg:grid-cols-4 grid-cols-3 gap-6 sm:gap-3 lg:gap-2 rounded-lg px-6 py-4 mt-6">
            {/* Name/PAN */}
            <div className="col-span-3 max-lg:mb-6 lg:col-span-1 flex items-center gap-3">
              {firstName && (
                <Avatar
                  sizes=""
                  className="uppercase md:!w-14 md:!h-14 !bg-white/80 !text-slate-600 border border-slate-300"
                >
                  {firstName?.charAt(0)}
                </Avatar>
              )}
              <div>
                <h3 className="text-slate-800 mb-0.5 font-medium">
                  {loadingPartnerProfile ? (
                    <Skeleton width={50} />
                  ) : name.length < 12 ? (
                    name
                  ) : (
                    name.slice(0, 12) + '...'
                  )}
                </h3>
                <h4 className="text-stone-500 text-sm font-medium tracking-widest">{pan || '-'}</h4>
              </div>
            </div>
            {/* Email ID */}
            <div className="lg:text-right col-span-1 lg:col-span-1">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Email ID</h3>
              <h4 className="text-sm font-medium text-slate-800">
                {loadingPartnerProfile ? (
                  <Skeleton width={70} className="ml-auto" />
                ) : email.length <= 18 ? (
                  email
                ) : (
                  email.slice(0, 18) + '...'
                )}
              </h4>
            </div>
            {/* Phone */}
            <div className="lg:text-right col-span-1 lg:col-span-1">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Phone No.</h3>
              <h4 className="text-sm font-medium text-slate-800">
                {loadingPartnerProfile ? <Skeleton width={70} className="ml-auto" /> : phone || '-'}
              </h4>
            </div>
            {/* Aadhaar */}
            <div className="lg:text-right col-span-1 lg:col-span-1">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Aadhaar</h3>
              <h4 className="text-sm font-medium text-slate-800">
                {loadingPartnerProfile ? (
                  <Skeleton width={70} className="ml-auto" />
                ) : panLinkedAadhaar ? (
                  `**** **** ${panLinkedAadhaar}`
                ) : (
                  '-'
                )}
              </h4>
            </div>
            {/* GST */}

            <div className="lg:text-right col-span-1 lg:col-span-2">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">GST Number</h3>
              <h4 className="text-sm font-medium text-slate-800">
                {loadingPartnerProfile ? (
                  <Skeleton width={70} className="ml-auto" />
                ) : gst ? (
                  `***** ***** ${gst.slice(-5)}`
                ) : (
                  '-'
                )}
              </h4>
            </div>

            {/* Bank Details - Account number */}
            <div className="lg:text-right col-span-1 lg:col-span-1">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">
                Account number
              </h3>
              <p className="text-sm font-medium text-slate-800">
                {loadingPartnerProfile ? (
                  <Skeleton width={70} className="ml-auto" />
                ) : (
                  accountNumber || '-'
                )}
              </p>
            </div>

            {/* Bank Details - IFSC Code */}
            <div className="lg:text-right col-span-1 lg:col-span-1">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">IFSC Code</h3>
              <p className="text-sm font-medium text-slate-800">
                {loadingPartnerProfile ? (
                  <Skeleton width={70} className="ml-auto" />
                ) : (
                  accountIfsc || '-'
                )}
              </p>
            </div>
          </div>
        )}
      </div>
      {profileData?.features?.enableInvoices && (
        <>
          {/* Invoices Table */}
          <Tables
            columns={columns}
            rows={rows}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
            handleChangeSort={() => {}}
            handleRowClick={() => {}}
            rowsPerPage={10}
            page={0}
            totalData={rows.length}
            pagination={false}
            disableCursor={true}
          />
        </>
      )}
    </>
  );
};

Profile.displayName = 'Profile';

export default Profile;
