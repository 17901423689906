import React from 'react';
import useGetPartnerProfile from '../../hooks/useGetPartnerProfile';
import { twoDecimalNumber } from '../../utils/numbers';

interface CommissionTableProps {}

const CommissionTable: React.FC<CommissionTableProps> = () => {
  const { data, isLoading } = useGetPartnerProfile();
  const { invoiceDiscountingTerms } = data?.data || {};

  return (
    <div className="my-6 max-w-6xl mx-auto">
      <h1 className="text-xl lg:text-2xl font-semibold text-slate-800">Commission Table</h1>

      <div className="mt-8 rounded-xl border border-stone-300">
        <div className="bg-black/[0.02] p-6 text-center text-base lg:text-xl font-bold text-slate-700 border-b border-b-stone-300">
          Total inflow for the month IRR (in Cr)
        </div>
        <div className="grid grid-cols-2 bg-black/[0.02] divide-x divide-stone-300">
          <div className="p-6">
            <p className="font-semibold text-slate-700 text-base lg:text-xl text-center justify-center items-center">
              Range
            </p>
          </div>
          <div className="p-6">
            <p className="font-semibold text-slate-700 text-base lg:text-xl text-center justify-center items-center">
              Invoice Discounting
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 w-full border-t border-t-stone-300 divide-x divide-y divide-stone-200 ">
          {!isLoading &&
            invoiceDiscountingTerms.map((term, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="grid grid-cols-1 md:grid-cols-3 p-6 w-full justify-between items-center font-medium text-sm md:text-base lg:text-lg ">
                    <span className="max-md:text-start text-center">
                      {index > 0 && '>'}
                      {twoDecimalNumber(term.rangeLower / 10000000)}
                    </span>
                    <span className="max-md:text-start text-center">-</span>
                    <span className="max-md:text-start text-center">
                      {twoDecimalNumber(term.rangeUpper / 10000000)}
                    </span>
                  </div>

                  <div className="p-6 font-medium text-sm md:text-base lg:text-lg text-center justify-center items-center">
                    {twoDecimalNumber(term.commissionPercentage)}%
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 border border-stone-300 my-6 rounded-xl">
        <div className="bg-black/[0.02] p-6 items-center justify-center flex">
          <p className="text-base lg:text-xl font-bold text-slate-700">Asset Leasing</p>
        </div>
        <div className="col-span-2 p-6 text-center text-sm md:text-base lg:text-lg font-medium">
          <p>
            1% across all Asset leasing investments(Commissions are excluding GST of invested
            amount)
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 border border-stone-300 my-6 rounded-xl">
        <div className="bg-black/[0.02] p-6  flex justify-center items-center">
          <p className="text-base lg:text-xl font-bold text-slate-700">Digital Gold</p>
        </div>
        <div className="col-span-2 p-6 text-center text-sm md:text-base lg:text-lg font-medium">
          <p>0.5% across all gold investments(Commissions are excluding GST of invested amount)</p>
        </div>
      </div>
    </div>
  );
};

CommissionTable.displayName = 'CommissionTable';

export default CommissionTable;
