import React from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
interface CardComponentProps {
  title: string;
}

const SuccessCardComponent: React.FC<CardComponentProps> = ({ title }) => {
  return (
    <div className="max-w-md mx-auto mt-[15%] bg-white shadow-lg rounded-xl overflow-hidden">
      <div className="p-6">
        <div className="flex items-center justify-center mb-6">
          <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center">
            <CheckCircleRoundedIcon className="w-6 h-6 text-green-700" />
          </div>
        </div>
        <div className="text-center">
          <h2 className="text-3xl font-semibold">{title}</h2>
        </div>
        <div className="text-center mt-10">
          <p className="text-gray-600">
            Need Help?{" "}
            <a
              href="mailto:support@tapinvest.in"
              className="text-gray-600 underline underline-offset-4"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessCardComponent;
