import React from 'react';
import { ClickingTimer } from '../../../components/Fields/ClickingTime';
import InfoIcon from '@mui/icons-material/Info';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { Box, Button, Checkbox, FormControlLabel, Link, Skeleton } from '@mui/material';
import { fourDecimalNumberWithoutRounding, getRupeeWithDecimal } from '../../../utils/numbers';
import { useMobileSize } from '../../../hooks/useScreenSize';
import { TimerComponent } from '../../DealDetail/DigitalGold/components/TimerComponent';
import RocketIcon from '@mui/icons-material/Rocket';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MessageCycler from '../../DealDetail/DigitalGold/components/MessageCycler';

const messages = [
  {
    text: 'If the time expires while you complete the payment, you will receive gold based on the updated price.',
    icon: <RocketIcon className="text-amber-700" style={{ width: '14px', height: '14px' }} />,
  },
  {
    text: 'The buying and selling prices may fluctuate based on live market conditions.',
    icon: <LocalOfferIcon className="text-amber-700" style={{ width: '14px', height: '14px' }} />,
  },
];

interface DetailsProps {
  loading: boolean;
  setShowLenderOnboardingAgreement: (value: boolean) => void;
  makeInvestment: () => void;
  data: {
    amount: number;
    email: string;
    expiryDateTime: string;
    priceExpiresAt: string | number;
    clientInvestmentId: number;
    price: number;
    blockId: string;
    quantity: number;
    metal: string;
    amountPayable: number | null;
    key: string;
    name: string;
    partnerName: string;
    phone: string;
  };
}

// Function to convert time to milliseconds
const convertEpochToLocalMilliseconds = (time: string) => {
  const userTime = new Date().getTime();
  return parseInt(time) - userTime;
};

const GoldDetails: React.FC<DetailsProps> = ({ data, loading, makeInvestment }) => {
  const [checked, setChecked] = React.useState(true);
  const isDisabled =
    loading || !data || parseInt(data?.expiryDateTime) < new Date().getTime() || !checked;
  const isMobile = useMobileSize();
  const buttonStyles = {
    marginTop: '20px',
    borderRadius: '12px',
    border: isDisabled ? '' : '1px solid #D97706',
    background: isDisabled
      ? '#D3D3D3'
      : 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 119.05%), #D97706',
    boxShadow: isDisabled ? 'none' : '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
    color: 'white',
    width: '100%',
    padding: '8px 16px',
    transition: 'background 0.3s ease-in-out, transform 0.3s ease-in-out',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };

  return (
    <div className="flex flex-col">
      <div
        className={`bg-white rounded-lg p-5 ${isMobile && 'mb-5'}`}
        style={{
          minWidth: '450px',
          maxWidth: '450px',
          maxHeight: '500px',
          boxShadow:
            '0px 64px 18px 0px rgba(0, 0, 0, 0.00), 0px 41px 16px 0px rgba(0, 0, 0, 0.01), 0px 23px 14px 0px rgba(0, 0, 0, 0.02), 0px 10px 10px 0px rgba(0, 0, 0, 0.03), 0px 3px 6px 0px rgba(0, 0, 0, 0.04)',
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex ">
            <SubdirectoryArrowRightRoundedIcon
              className="text-stone-400"
              sx={{ width: '15px', height: '15px' }}
            />
            <p className="text-xs text-stone-400 font-medium">
              {loading ? (
                <Skeleton width={60} />
              ) : data?.partnerName.length > 20 ? (
                `Requested by ${data?.partnerName.substring(0, 20)}...`
              ) : (
                'Requested by ' + data?.partnerName
              )}
            </p>
          </div>
          {loading ? (
            <Skeleton width={60} />
          ) : (
            <ClickingTimer
              time={convertEpochToLocalMilliseconds(data?.expiryDateTime) || 0}
              sx="text-xs text-stone-600 bg-stone-200 px-2 py-1 rounded-2xl"
            />
          )}
        </div>
        <div className="flex flex-col items-center mt-8 gap-1 mb-10">
          <h3 className="text-lg font-bold ">
            Investment in Digital <span className="text-amber-700">{data?.metal}</span>
          </h3>

          <h3 className="text-base font-semibold">
            {fourDecimalNumberWithoutRounding(Number(data?.amount) / Number(data?.price))} gm{' '}
            <span className="text-stone-400 font-medium">₹{data?.amountPayable}</span>
          </h3>
        </div>
        <div className="mt-4 flex flex-col gap-4 px-3">
          <div className="flex justify-between">
            <p className="text-xs text-stone-500">LIVE PRICE</p>
            <p className="text-xs font-semibold text-stone-800">
              {loading ? <Skeleton width={60} /> : getRupeeWithDecimal.format(data?.price || 0)}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-xs text-stone-500">BUYING PRICE</p>
            <p className="text-xs font-semibold text-stone-800">
              {loading ? <Skeleton width={60} /> : getRupeeWithDecimal.format(data?.amount || 0)}
            </p>
          </div>{' '}
          <div className="flex justify-between">
            <p className="text-xs text-stone-500">GST</p>
            <p className="text-xs font-semibold text-stone-800">3%</p>
          </div>{' '}
        </div>
        <div
          className="border-gray-300 mt-4 pt-4"
          style={{
            backgroundSize: '24px 1px',
            backgroundRepeat: 'repeat-x',
            backgroundImage:
              'linear-gradient(to right, rgba(0,0,0,0.12) 35%, rgba(255,255,255,0) 0%)',
          }}
        >
          <div className="flex justify-between px-3">
            <p className="text-base text-stone-500">To be paid:</p>
            <p className="text-base font-semibold text-green-700">
              {loading ? (
                <Skeleton width={60} />
              ) : (
                getRupeeWithDecimal.format(data?.amountPayable || 0)
              )}
            </p>
          </div>
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  className="custom-checkbox"
                  defaultChecked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: '#16A34A',
                    },
                  }}
                />
              }
              label={
                <span className="text-sm text-stone-600 opacity-70">
                  By proceeding, you agree to our{' '}
                  <Link
                    href="https://tapinvest.in/tnc"
                    target="_blank"
                    underline="hover"
                    style={{
                      cursor: 'pointer',
                      color: '#57534e',
                      textDecoration: 'underline',
                      textUnderlineOffset: '2px',
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </span>
              }
            />
          </Box>

          <Button
            style={buttonStyles}
            onMouseDown={(e) => {
              if (!isDisabled) e.currentTarget.style.background = '#D97706';
            }}
            onMouseUp={(e) => {
              if (!isDisabled)
                e.currentTarget.style.background =
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 119.05%), #D97706';
            }}
            onClick={() => {
              if (!isDisabled) makeInvestment();
            }}
            disabled={isDisabled}
            className="font-semibold capitalize"
          >
            pay now
          </Button>
        </div>
      </div>
      <div className="flex flex-col ">
        <div className="px-5 py-3 bg-amber-50 mt-8 border border-amber-200 rounded-xl flex items-center gap-2">
          <InfoIcon className="text-amber-700" style={{ width: '14px', height: '14px' }} />
          <p className="text-amber-700 text-xs font-medium">
            Price will be changed in the next{' '}
            <span style={{ display: 'inline-block' }}>
              <TimerComponent expiresAt={data?.priceExpiresAt} />
            </span>{' '}
            minutes
          </p>
        </div>
        <MessageCycler messages={messages} />
      </div>
    </div>
  );
};

export default GoldDetails;
