// src/components/InvestmentDetails.tsx
import React from 'react';
import { ClickingTimer } from '../../../components/Fields/ClickingTime';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { Box, Button, Checkbox, FormControlLabel, Link, Skeleton } from '@mui/material';
import { getRupeeWithDecimal } from '../../../utils/numbers';
import { useMobileSize } from '../../../hooks/useScreenSize';

interface DetailsProps {
  loading: boolean;
  setShowLenderOnboardingAgreement: (value: boolean) => void;
  makeInvestment: () => void;
  setPhone: (phone: string) => void;
  data: {
    about: string;
    amount: number;
    email: string;
    expiryDateTime: string;
    interest: string;
    isin: string;
    key: string;
    logo: string;
    dealName: string;
    maturityDate: string;
    miniSummary: null | string;
    name: string;
    partnerName: string;
    phone: string;
    principal: string;
    qty: number;
    returnAmount: number;
    settlementDate: string;
    unitConsiderationAmount: number;
    yield: number;
    // other
    investmentType: string;
    totalProjectedValue: number;
    payoutDate: string;
    loading: boolean;
    returns: number;
    walletBalance: number;
  };
}

// Function to convert time to milliseconds
const convertEpochToLocalMilliseconds = (time: string) => {
  const userTime = new Date().getTime();
  return parseInt(time) - userTime;
};

const BondsDetails: React.FC<DetailsProps> = ({ data, loading, makeInvestment, setPhone }) => {
  const [checked, setChecked] = React.useState(true);
  const isDisabled =
    loading || !data || parseInt(data?.expiryDateTime) < new Date().getTime() || !checked;
  const isMobile = useMobileSize();
  const buttonStyles = {
    marginTop: '20px',
    borderRadius: '12px',
    border: isDisabled ? '' : '1px solid #15803D',
    background: isDisabled
      ? '#D3D3D3'
      : 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A',
    boxShadow: isDisabled ? 'none' : '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
    color: 'white',
    width: '100%',
    padding: '8px 16px',
    transition: 'background 0.3s ease-in-out, transform 0.3s ease-in-out',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };

  return (
    <div
      className={`bg-white rounded-lg p-5 ${isMobile && 'mb-5'}`}
      style={{
        minWidth: '450px',
        maxWidth: '450px',
        maxHeight: '600px',

        boxShadow:
          '0px 64px 18px 0px rgba(0, 0, 0, 0.00), 0px 41px 16px 0px rgba(0, 0, 0, 0.01), 0px 23px 14px 0px rgba(0, 0, 0, 0.02), 0px 10px 10px 0px rgba(0, 0, 0, 0.03), 0px 3px 6px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      <div className="flex justify-between items-center">
        <div className="flex ">
          <SubdirectoryArrowRightRoundedIcon
            className="text-stone-400"
            sx={{ width: '15px', height: '15px' }}
          />
          <p className="text-xs text-stone-400 font-medium">
            Requested by {loading ? <Skeleton width={60} /> : data?.partnerName}
          </p>
        </div>
        {loading ? (
          <Skeleton width={60} />
        ) : (
          <ClickingTimer
            time={convertEpochToLocalMilliseconds(data?.expiryDateTime) || 0}
            sx="text-xs text-stone-600 bg-stone-200 px-2 py-1 rounded-2xl"
          />
        )}
      </div>
      <div className="flex flex-col items-center mt-8 gap-1 mb-10">
        <img src={data?.logo} alt="Logo" className="w-16 h-16 mr-3 rounded-full" />

        <h3 className="text-lg font-bold">Investment in {data?.dealName}</h3>

        <h3 className="text-base font-semibold">
          {data?.qty} units{' '}
          <span className="text-stone-400 font-medium">{data?.unitConsiderationAmount}/Unit</span>
        </h3>
      </div>
      <div className="mt-4 flex flex-col gap-4 px-3">
        <div className="flex justify-between">
          <p className="text-xs text-stone-500">INVESTMENT AMOUNT</p>
          <p className="text-xs font-semibold text-stone-800">
            {loading ? <Skeleton width={60} /> : getRupeeWithDecimal.format(data?.amount || 0)}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-xs text-stone-500">YIELD</p>
          <p className="text-xs font-semibold text-stone-800">
            {loading ? <Skeleton width={60} /> : data ? data?.yield + '%' : '-'}
          </p>
        </div>{' '}
        <div className="flex justify-between">
          <p className="text-xs text-stone-500">MATURITY DATE</p>
          <p className="text-xs font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              data?.maturityDate?.split('-').reverse().join('-') || 0
            )}
          </p>
        </div>{' '}
        <div className="flex justify-between">
          <p className="text-xs text-stone-500">EXPECTED RETURNS</p>
          <p className="text-xs font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              getRupeeWithDecimal.format(data?.returnAmount || 0)
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-xs text-stone-500">SETTLEMENT DATE</p>
          <p className="text-xs font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              data?.settlementDate?.split('-').reverse().join('-') || 0
            )}
          </p>
        </div>
      </div>
      <div
        className="border-gray-300 mt-4 pt-4"
        style={{
          backgroundSize: '24px 1px',
          backgroundRepeat: 'repeat-x',
          backgroundImage:
            'linear-gradient(to right, rgba(0,0,0,0.12) 35%, rgba(255,255,255,0) 0%)',
        }}
      >
        <div className="flex justify-between px-3">
          <p className="text-base text-stone-500">To be paid:</p>
          <p className="text-base font-semibold text-green-700">
            {loading ? <Skeleton width={60} /> : getRupeeWithDecimal.format(data?.amount || 0)}
          </p>
        </div>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                className="custom-checkbox"
                defaultChecked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: '#16A34A',
                  },
                }}
              />
            }
            label={
              <span className="text-sm text-stone-600 opacity-70">
                By proceeding, you agree to our{' '}
                <Link
                  href="https://app.tapinvest.in/BondsRDA.pdf"
                  target="_blank"
                  underline="hover"
                  style={{
                    cursor: 'pointer',
                    color: '#57534e',
                    textDecoration: 'underline',
                    textUnderlineOffset: '2px',
                  }}
                >
                  Risk Disclosure Agreement
                </Link>{' '}
                and{' '}
                <Link
                  href="https://tapinvest.in/tnc"
                  target="_blank"
                  underline="hover"
                  style={{
                    cursor: 'pointer',
                    color: '#57534e',
                    textDecoration: 'underline',
                    textUnderlineOffset: '2px',
                  }}
                >
                  Terms & Conditions
                </Link>
              </span>
            }
          />
        </Box>

        <Button
          style={buttonStyles}
          onMouseDown={(e) => {
            if (!isDisabled) e.currentTarget.style.background = '#15803D';
          }}
          onMouseUp={(e) => {
            if (!isDisabled)
              e.currentTarget.style.background =
                'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A';
          }}
          onClick={() => {
            if (!isDisabled) makeInvestment();
          }}
          disabled={isDisabled}
        >
          pay now
        </Button>
      </div>
    </div>
  );
};

export default BondsDetails;
