import { create } from 'zustand';
import { ONBOARDING_PAGE_TYPE } from './constants';
import { OnboardingPageType } from './types';
import { persist, createJSONStorage } from 'zustand/middleware';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

interface OnboardingStore {
  isContractModalOpen: boolean;
  nextPage: OnboardingPageType;
  setNextPage: (nextPage: OnboardingPageType) => void;
  setIsContractModalOpen: (isContractModalOpen: boolean) => void;
}

const noAuthToast = () =>
  toast.error('Authorization required', {
    duration: 2500,
    position: 'top-center',
  });

export const useOnboardingStore = create(
  persist<OnboardingStore>(
    (set, get) => ({
      isContractModalOpen: false,
      nextPage: ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION,
      setNextPage: (nextPage: OnboardingPageType) => {
        return set({ nextPage });
      },
      setIsContractModalOpen: (isContractModalOpen: boolean) => {
        return set({ isContractModalOpen });
      },
    }),
    {
      name: 'onboarding_step',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export const useOnboardingNavigate = () => {
  const navigate = useNavigate();
  const { setIsContractModalOpen, setNextPage } = useOnboardingStore(
    ({ setIsContractModalOpen, setNextPage }) => ({ setIsContractModalOpen, setNextPage }),
  );

  return {
    handleNextPage: (nextPage: OnboardingPageType, data?: string) => {
      if (data) {
        localStorage.setItem('token', data);
        if (nextPage === ONBOARDING_PAGE_TYPE.LOGGED_IN) {
          navigate('/deals');
          setNextPage(ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION);
        } else {
          setNextPage(nextPage);
        }
      } else if (nextPage === ONBOARDING_PAGE_TYPE.LOGGED_IN) {
        navigate('/deals');
        // Might change based on which one is the first step
        setNextPage(ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION);
      } else if (nextPage === ONBOARDING_PAGE_TYPE.NEXT_PAGE_INFO_AVAILABLE_AFTER_AUTHENTICATION) {
        noAuthToast();
        // Might change based on which one is the first step
        setNextPage(ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION);
        localStorage.removeItem('token');
      } else if (nextPage === ONBOARDING_PAGE_TYPE.CONTRACT_SIGNING) {
        // Open Agreement Modal
        setIsContractModalOpen(true);
      } else {
        setNextPage(nextPage);
      }
    },
  };
};
