export const SORTABLE_COLUMNS = {
  familyName: 'familyName',
  numberOfClients: 'numberOfClients',
  investedAmount: 'investedAmount',
};

export const FAMILY_EVENTS = {
  SELECT_CLIENT: 'Select_Client',
  Family_Account_Page: 'Family_Account_Page',
  Add_New_Family_Account: 'Add_New_Family_Account',
  Family_Account_Page_View: 'Family_Account_Page_View',
  Add_Family_Member: 'Add_Family_Member',
};
