import { useQuery, PATH, Call } from '../../services/NetworkManager';

export function getClientDetails({ queryKey }) {
  const id = queryKey[0].split(',')[1];

  return Call({
    method: 'get',
    path: PATH.client.replace('{{clientId}}', id),
  });
}

export function getClientBankDetails({ queryKey }) {
  const id = queryKey[0].split(',')[1];

  return Call({
    method: 'get',
    path: PATH.clientBank.replace('{{clientId}}', id),
  });
}

export function getClientStats({ queryKey }) {
  const id = queryKey[0].split(',')[1];

  return Call({
    method: 'get',
    path: PATH.clientStatisticsData.replace('{{clientId}}', id),
  });
}

export function getClientTaxReportStatus({ queryKey }) {
  const [_, id] = queryKey[0].split(',');

  return Call({
    method: 'get',
    path: PATH.taxReportStatus.replace('{{clientId}}', id).replace('{{year}}', '2024'),
  });
}

export function useClientDetails(clientId?: string) {
  const { data, isLoading } = useQuery(`${['client', clientId]}`, getClientDetails);
  return { data, isLoading };
}

export function useClientStats(clientId?: string) {
  const { data, isLoading, refetch } = useQuery(`${['clientInfo', clientId]}`, getClientStats);
  return { data, isLoading, refetch };
}

export function useClientBank(clientId?: string) {
  const { data, isLoading } = useQuery(`${['clientBank', clientId]}`, getClientBankDetails);
  return { data, isLoading };
}



export function useClientTaxReportStatus(clientId: string) {
  const { data, isLoading } = useQuery(
    `${['clientTaxReport', clientId]},`,
    getClientTaxReportStatus,
  );
  return { data, isLoading };
}

export const postWithdrawMoney = ({
  clientPublicId,
  amount,
}: {
  clientPublicId: string;
  amount: string;
}) => {
  return Call({
    method: 'post',
    path: PATH.withdrawMoney,
    data: {
      clientPublicId,
      amount,
    },
  });
};
