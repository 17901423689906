import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Box, IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as SuccessIcon } from '../../assets/svg/successCloud.svg';
import toast from 'react-hot-toast';
import { postCmrFileUpload } from './useBondDealDetails';
import { BONDS_EVENTS } from './constants';
import { useAnalytics } from '../../hooks/useAnalytics';

const CMRUpload = ({ open, onClose, refetchCmrStatus, clientId }) => {
    const [cmrFile, setCmrFile] = useState(null);
    const [error, setError] = useState('');
    const [successScreen, setSuccessScreen] = useState(false);
    const { track } = useAnalytics();

  const showSuccessScreen = () => {
    setSuccessScreen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        setError('Please upload a PDF file.');
      } else if (file.size > 5 * 1024 * 1024) {
        setError('File size should not exceed 5MB.');
      } else {
        setCmrFile(file);
        setError('');
      }
    }
  };

  const handleRemoveFile = () => {
    setCmrFile(null);
    setError('');
  };

  const handleSubmit = () => {
    if (cmrFile) {
      const formData = new FormData();
      formData.append('file', cmrFile);
      formData.append('client-public-id', clientId);

      track({ eventName: BONDS_EVENTS.Bonds_Upload_CMR });

      postCmrFileUpload(formData)
        .then((data) => {
          showSuccessScreen();
        })
        .catch((error) => {
          toast.error(
            `${error?.response?.data?.message || 'Failed to upload CMR Copy, please try again.'}`,
            {
              duration: 2500,
              position: 'top-center',
            },
          );
        })
        .finally(() => {
          refetchCmrStatus();
        });
    }
  };

  const handleOpenPDF = () => {
    const pdfPath = 'https://cdn.leafround.com/promo/CMRCopyGuide.pdf';
    window.open(pdfPath, '_blank');
  };

  useEffect(() => {
    if (!open) {
      handleRemoveFile();
    }
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '40%',
          display: 'flex',
          justifyContent: 'space-between',
        },
      }}
    >
      {successScreen ? (
        <div className="flex flex-col justify-between h-full">
          <img src="https://cdn.leafround.com/promo/black_grid.png" alt="CMR Upload Success" />
          <div className="p-5 flex flex-col items-center justify-center text-center">
            <div className="flex justify-center mb-4">
              <SuccessIcon className="w-36 h-36" />
            </div>
            <h1 className="text-2xl font-bold mb-4">CMR Uploaded Successfully!</h1>
            <p className="text-gray-600 mb-6">
              While we are verifying your documents, you can start exploring bonds listed on Tap
              Invest.
            </p>
            <button
              className="text-sm  mx-auto text-white rounded-xl font-bold mt-0 h-10 py-0 shadow !w-32 items-center flex justify-center"
              style={{
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A',
                boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
              }}
              onClick={onClose}
            >
              Explore Bonds
            </button>
          </div>
          <img
            src="https://cdn.leafround.com/promo/black_grid.png"
            alt="CMR Upload Success"
            className="transform scale-x-[-1] scale-y-[-1]"
          />
        </div>
      ) : (
        <>
          <Box sx={{ px: 3 }}>
            <Box sx={{ padding: '32px 0 24px 0' }}>
              <IconButton
                onClick={onClose}
                className="!text-green-700 rounded-full !bg-[#15803D1F]"
              >
                <KeyboardBackspaceIcon />
              </IconButton>
              <Typography variant="h6" gutterBottom className="!text-2xl !font-semibold !mt-6">
                Upload Demat CMR Copy
              </Typography>
              <Typography variant="body2" gutterBottom className="!text-base !mt-1">
                To unlock bonds, we need your CMR Copy from your broker of choice.
              </Typography>
            </Box>
            <hr className="-mx-6 mb-6" />
            <Box className="border-2 border-dashed border-gray-300 rounded-xl flex flex-col items-center justify-center self-stretch h-50 mb-2 py-[16px] px-4 gap-1 cursor-pointer relative h-[200px]">
              <input
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
                className="absolute h-full w-full opacity-0 cursor-pointer"
                id="file-upload"
              />
              {!cmrFile ? (
                <>
                  <Box
                    className="flex w-10 h-10 p-[9.5px] justify-center items-center rounded-[8px] border border-gray-200 bg-white bg-opacity-40 shadow"
                    style={{
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    }}
                  >
                    <DriveFolderUploadIcon sx={{ fontSize: 20, color: '#ccc' }} />
                  </Box>
                  <Typography variant="body2">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </Typography>
                  <Typography variant="caption">Upload PDF file up to 5MB here</Typography>
                  {error && (
                    <Typography variant="caption" color="error">
                      {error}
                    </Typography>
                  )}
                </>
              ) : (
                <Box className={'flex items-center justify-center'}>
                  <Typography variant="body2">{cmrFile.name}</Typography>
                  <IconButton onClick={handleRemoveFile} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>

            <button
              className="text-sm disabled:bg-green-700/60 mx-auto text-white rounded-xl font-bold mt-4 h-10 py-0 shadow !w-32 items-center flex justify-center"
              style={
                cmrFile
                  ? {
                      background:
                        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A',
                      boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
                    }
                  : { background: 'rgba(0, 0, 0, 0.12)' }
              }
              disabled={!cmrFile}
              onClick={handleSubmit}
              type="button"
            >
              Submit File
            </button>
            <Box className="border border-stone-200 bg-stone-100 rounded-xl p-5 mt-6">
              <Typography className="text-stone-900 !text-sm font-semibold">
                What is it and how to get it?
              </Typography>
              <Typography className="text-stone-500 !text-xs !font-medium !mt-[6px]">
                Your CMR Copy gives us details on Demat account and the bank account where you will
                get the payout.
              </Typography>
              <Typography
                component="div"
                className="!text-green-700 underline font cursor-pointer !text-xs !mt-3"
                onClick={handleOpenPDF}
              >
                See how to get your CMR Copy
              </Typography>
            </Box>
          </Box>
          <Typography
            component="div"
            className="underline underline-offset-2 cursor-pointer text-center !py-4 text-slate-600 border-t border-t-stone-300"
            onClick={onClose}
          >
            I will do it later
          </Typography>
        </>
      )}
    </Drawer>
  );
};

export default CMRUpload;
