// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export var app = initializeApp(firebaseConfig);

// Function to fetch and activate Remote Config
export var fetchAndActivateRemoteConfig = async (remoteConfigKey) => {
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 30000, // 30 seconds
    fetchTimeoutMillis: 30000, // 30 seconds (optional)
  };
  try {
    await fetchAndActivate(remoteConfig);
    const val = getValue(remoteConfig, remoteConfigKey);
    return val?._value || null;
  } catch (error) {
    console.error("Error fetching and activating remote config:", error);
    return null;
  }
};
