import React, { useState } from 'react';
import { FAMILY_EVENTS } from '../constants';
import { useForm } from 'react-hook-form';
import Input from '../../../components/Fields/Input';
import toast from 'react-hot-toast';
import SelectClient from '../../DealDetail/SelectClient';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AddIcon, CircularProgress, IconButton, Tooltip } from '../../../assets/mui';
import clsx from 'clsx';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface AddOrEditClientProps {
  initialData?: any;
  familyLabel: string;
  handleNext: (userDetails: any[]) => void; // Moves to the primary client selection step
  dataObject: any; // The data object being passed down from the parent component
}

const AddOrEditClient: React.FC<AddOrEditClientProps> = ({
  initialData,
  familyLabel,
  handleNext,
  dataObject,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showExistingClientForm, setShowExistingClientForm] = useState(false);
  const [showNewClientForm, setShowNewClientForm] = useState(false);
  const [clientDetails, setClientDetails] = useState<
    Array<{ firstName: string; lastName: string }>
  >([{ firstName: '', lastName: '' }]);
  const [existingClients, setExistingClients] = useState<any[]>([]);
  const [clientDropdownLoading, setClientDropdownLoading] = useState(false);
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    setIsLoading(true);

    const invalidClientsLastName = clientDetails.some(
      (client) => client.firstName.trim() !== '' && client.lastName.trim() === '',
    );
    const invalidClientsFirstName = clientDetails.some(
      (client) => client.firstName.trim() === '' && client.lastName.trim() !== '',
    );
    if (invalidClientsFirstName) {
      toast.error('First name is required');
      setIsLoading(false);
      return;
    }
    if (invalidClientsLastName) {
      toast.error('Last name is required');
      setIsLoading(false);
      return;
    }
    if (
      existingClients.length === 0 &&
      clientDetails.every(
        (client) => client.firstName.trim() === '' && client.lastName.trim() === '',
      )
    ) {
      toast.error('Please add at least one client to continue.');
      setIsLoading(false);
      return;
    }
    try {
      const userDetails = [];

      // Process existing clients
      existingClients.forEach((client) => {
        userDetails.push({
          clientId: client.clientPublicId,
          firstName: client.name,
          phone: client.phone,
          email: client.email,
          level: 'SECONDARY_MEMBER',
        });
      });

      // Process new clients
      clientDetails.forEach((client) => {
        if (client.firstName.trim() !== '' || client.lastName.trim() !== '') {
          userDetails.push({
            firstName: client.firstName,
            lastName: client.lastName,
            level: 'SECONDARY_MEMBER',
          });
        }
      });

      // Updating the dataObject with userDetails before moving to the next step
      handleNext(userDetails);
    } catch (error) {
      console.error(error);
      toast.error('Failed to save clients. Please try again.');
      setIsLoading(false);
    }
  };

  const toggleExistingClientForm = () => {
    setShowExistingClientForm(!showExistingClientForm);
  };

  const handleClientNameChange = (index: number, key: string, value: string) => {
    const newClientDetails = [...clientDetails];
    newClientDetails[index] = { ...newClientDetails[index], [key]: value };
    setClientDetails(newClientDetails);
  };

  const handleExistingClientSelect = (selectedClients: any[]) => {
    setExistingClients(selectedClients);
  };

  const toggleNewClientForm = () => {
    setShowNewClientForm(!showNewClientForm);
  };

  const addNewClientInput = () => {
    const lastClient = clientDetails[clientDetails.length - 1];

    if (lastClient.firstName.trim() === '' && lastClient.lastName.trim() === '') {
      toast.error('Please fill in the current client name before adding a new one.');
      return;
    }

    setClientDetails([...clientDetails, { firstName: '', lastName: '' }]);
  };

  const removeClientInput = (index: number) => {
    const newClientDetails = clientDetails.filter((_, i) => i !== index);
    setClientDetails(newClientDetails);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col max-w-96 gap-6">
      {/* Existing Client Section */}
      <div>
        <div className="flex gap-8 items-center">
          <button
            type="button"
            onClick={toggleExistingClientForm}
            className="rounded-full bg-[rgba(217,119,6,0.12)] flex items-center justify-center w-9 h-9"
          >
            {showExistingClientForm ? (
              <KeyboardArrowDownIcon className="text-[rgba(217,119,6,1)]" />
            ) : (
              <AddIcon className="text-[rgba(217,119,6,1)]" />
            )}
          </button>
          <div>
            <div className="text-base font-semibold">Add Existing Client</div>
            <p className="text-sm font-medium text-stone-500">
              You can add your existing clients to the family
            </p>
          </div>
        </div>
        {showExistingClientForm && (
          <div className="mt-6">
            <SelectClient
              client={existingClients}
              setClient={handleExistingClientSelect}
              eventName={FAMILY_EVENTS.SELECT_CLIENT}
              showAddNewClient={false}
              setLoading={setClientDropdownLoading}
              multiple
              value={initialData?.publicId}
            />
          </div>
        )}
      </div>

      {/* New Client Section */}
      <div>
        <div className="flex gap-8 items-center">
          <button
            type="button"
            onClick={toggleNewClientForm}
            className="rounded-full bg-[rgba(217,119,6,0.12)] flex items-center justify-center w-9 h-9"
          >
            {showNewClientForm ? (
              <KeyboardArrowDownIcon className="text-[rgba(217,119,6,1)]" />
            ) : (
              <AddIcon className="text-[rgba(217,119,6,1)]" />
            )}
          </button>
          <div className="">
            <div className="text-base font-semibold">Add New Client</div>
            <p className="text-sm font-medium text-stone-500">Add new clients to the family</p>
          </div>
        </div>
        {showNewClientForm && (
          <div className="mt-4">
            {clientDetails.map((client, index) => (
              <div key={index} className="flex items-center mt-4 gap-2">
                <Input
                  type="text"
                  value={client.firstName}
                  onChange={(e) => handleClientNameChange(index, 'firstName', e.target.value)}
                  placeholder="First Name"
                  className="flex-grow text-gray-600"
                />
                <Input
                  type="text"
                  value={client.lastName}
                  onChange={(e) => handleClientNameChange(index, 'lastName', e.target.value)}
                  placeholder="Last Name"
                  className="flex-grow text-gray-600"
                />

                {index < clientDetails.length - 1 && (
                  <Tooltip title="Delete this client" placement="top" arrow>
                    <IconButton
                      onClick={() => removeClientInput(index)}
                      className="ml-2"
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteOutlineOutlinedIcon className="text-red-600" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addNewClientInput}
              className={clsx('flex items-center gap-2 text-[#475569] font-semibold text-sm mt-4', {
                'cursor-not-allowed text-[#475569]/60': isLoading,
                'hover:text-[#475569]/80': !isLoading,
              })}
              disabled={isLoading}
            >
              <AddIcon fontSize="small" />
              Add another client
            </button>
          </div>
        )}
      </div>

      {/* Continue Button */}
      <button
        type="submit"
        disabled={isLoading}
        className={clsx(
          'bg-green-700 text-sm disabled:bg-green-700/60 text-white rounded-lg font-bold py-3 px-6 transition-all duration-300 ease-in-out transform hover:bg-green-800 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 w-fit',
          {
            'opacity-70 cursor-not-allowed': isLoading,
          },
        )}
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <CircularProgress style={{ color: 'white' }} className="text-white" size={20} />
            <span className="ml-3">Saving...</span>
          </div>
        ) : (
          'Save and Continue'
        )}
      </button>
    </form>
  );
};

AddOrEditClient.displayName = 'AddOrEditClient';

export default AddOrEditClient;
