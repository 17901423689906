import React, { useState } from 'react';
import Input from '../../../components/Fields/Input';
import { Field } from '../../../components/Fields/Field';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NetworkManager } from '../../../services';
import { kycError } from '../../../utils/toast';
import { PartnerOnboardingActions } from '../types';
import { useLayoutStore } from '../../../components/organisms/Layout/store';
import { PARTNERS_EVENTS, PARTNER_ONBOARDING_PAGE_TYPE } from '../constants';
import KYCButton from '../../Onboarding/KYCButton';
import toast from 'react-hot-toast';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { getPartnerKycDetails } from '../usePartners';

interface PanVerificationProps extends PartnerOnboardingActions {}

const panSchema = yup
  .string()
  .matches(
    /^[a-zA-Z]{3}[abcfghljptfABCFGHLJPTF]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
    'Invalid PAN format',
  );

const PanVerification: React.FC<PanVerificationProps> = ({ handleStep }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isVerifyStep, setVerifyStep] = useState(false);

  const { partnerId, setPartnerDetails } = useLayoutStore();

  const { track } = useAnalytics();

  const schema = yup.object().shape({
    pan: panSchema,
    panSaved: yup.bool().default(false),
    name: yup.string(),
    panConfirmed: yup.bool().default(false),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { pan: null },
  });

  const verifyPan = (pan: string) => {
    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.partnerPanInfo.replace('{{pan}}', pan),
    });
  };

  const confirmPartnerPan = (pan: string) => {
    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.savePartnerPanInfo
        .replace('{{pan}}', pan)
        .replace('{{subPartnerPublicId}}', partnerId),
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { pan } = data;

    setIsLoading(true);

    if (isVerifyStep) {
      try {
        await confirmPartnerPan(pan);
        await getPartnerKycDetails({ partnerId, setPartnerDetails });
        handleStep(PARTNER_ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION);
        setVerifyStep(false);
        setValue('pan', pan);
        handleEntryEvent(PARTNERS_EVENTS.KYC_PAN_Verify);
      } catch (error) {
        if (error?.response?.status?.toString().startsWith('5')) {
          toast.error('Something went wrong! Please try again', {
            duration: 2500,
            position: 'top-center',
          });
        } else if (error?.response?.status.toString().startsWith('4')) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: 'top-center',
          });
        } else {
          kycError('Something went wrong! Please try again');
        }
      }
    } else {
      // Making use of existing onboarding PAN verification API
      try {
        const panRes = await verifyPan(pan);
        if (!panRes.error) {
          setValue('panSaved', true);
          setValue('name', `${panRes.data?.firstName} ${panRes.data?.lastName}`);
          setVerifyStep(true);
        } else {
          const errorMessage = panRes?.error;
          if (errorMessage?.includes('PAN does not exist')) {
            kycError('PAN does not exist');
          } else if (errorMessage?.includes('Please try again after sometime')) {
            kycError('Please try again after sometime');
          } else {
            kycError('Something went wrong! Please try again');
          }
        }
      } catch (error) {
        if (error?.response?.status?.toString().startsWith('5')) {
          toast.error('Something went wrong! Please try again', {
            duration: 2500,
            position: 'top-center',
          });
        } else if (error?.response?.status.toString().startsWith('4')) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: 'top-center',
          });
        } else {
          kycError('Something went wrong! Please try again');
        }
      }
    }

    setIsLoading(false);
  };

  const handleEntryEvent = (event) => {
    track({ eventName: event });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col max-w-96">
      <Field label="PAN Number" error={errors.pan?.message}>
        <Input
          onClick={() => handleEntryEvent(PARTNERS_EVENTS.KYC_PAN_Entry)}
          type="text"
          placeholder="Enter PAN"
          {...register('pan', {
            onChange: (e) => {
              handleEntryEvent('ONBOARDING_EVENTS.KYC_PAN_Entry');
              setValue('panSaved', false);
              setValue('name', '');
              setValue('pan', e.target.value);
              setIsLoading(false);
              setVerifyStep(false);
            },
          })}
        />
      </Field>
      {isVerifyStep && getValues('name') && (
        <Field label="Name as per PAN" className="mt-6">
          <div className="capitalize font-medium">{getValues('name').toLowerCase()}</div>
        </Field>
      )}

      <KYCButton
        text={isVerifyStep ? "Yes, that's my partner" : 'Validate PAN'}
        isLoading={isLoading}
      />
    </form>
  );
};

PanVerification.displayName = 'PanVerification';

export default PanVerification;
