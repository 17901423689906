import React, { useCallback } from 'react';
import {
  PARTNERS_EVENTS,
  PARTNER_ONBOARDING_PAGE_TYPE,
  PARTNER_ONBOARDING_STEPS,
} from '../constants';
import { PartnerOnboardingActions, PartnerOnboardingPageType } from '../types';
import clsx from 'clsx';
import { ArrowForward, Check } from '@mui/icons-material';
import { useLayoutStore } from '../../../components/organisms/Layout/store';
import { useAnalytics } from '../../../hooks/useAnalytics';

interface FirstStepProps extends PartnerOnboardingActions {
  isIdentityVerified: boolean;
  isPanVerified: boolean;
  isAadhaarVerified: boolean;
  isBankVerified: boolean;
}

const FirstStep: React.FC<FirstStepProps> = (props) => {
  const { track } = useAnalytics();

  const { handleStep, ...verificationKeys } = props;
  const { partnerDetails } = useLayoutStore();

  const handleCompleteNow = useCallback((step: string) => {
    handleStep(step as PartnerOnboardingPageType);
    if (step === PARTNER_ONBOARDING_PAGE_TYPE.IDENTITY_VERIFICATION) {
      track({ eventName: PARTNERS_EVENTS.Identity_Verification_Initiated });
    }
    if (step === PARTNER_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION) {
      track({ eventName: PARTNERS_EVENTS.KYC_PAN_Initiated });
    }
    if (step === PARTNER_ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION) {
      track({ eventName: PARTNERS_EVENTS.KYC_Aadhaar_Initiated });
    }
    if (step === PARTNER_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION) {
      track({ eventName: PARTNERS_EVENTS.KYC_Bank_Initiated });
    }
  }, [handleStep, track]);

  return (
    <div className="px-4">
      {Object.entries(PARTNER_ONBOARDING_STEPS).map(([step, stepValues]) => {
        const isVerified = verificationKeys[stepValues.verifiedKey];
        const isPreviousStepVerified =
          verificationKeys[PARTNER_ONBOARDING_STEPS[stepValues.previousStep]?.verifiedKey] ||
          stepValues.previousStep === PARTNER_ONBOARDING_PAGE_TYPE.FIRST_STEP;
        const isLastStep = step === PARTNER_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION;
        const isIdentityStep = step === PARTNER_ONBOARDING_PAGE_TYPE.IDENTITY_VERIFICATION;

        return (
          <div
            key={step}
            className={clsx('relative border-l-2 pl-6', {
              'border-none': isLastStep,
              'border-l-amber-600/15': !isVerified,
              'border-l-green-700': isVerified,
            })}
          >
            {/* Completion status icon */}
            <div
              className={clsx(
                'flex justify-between items-center rounded-full absolute border-2 w-9 h-9 border-primary',
                {
                  'bg-orange-100': !isVerified,
                  'bg-[#ddeadb]': isVerified,
                  '-left-[19px]': !isLastStep,
                  '-left-[17px]': isLastStep,
                },
              )}
            >
              {isVerified ? (
                <Check fontSize="small" className="text-green-700 mx-auto" />
              ) : (
                <ArrowForward fontSize="small" className="text-amber-700 mx-auto" />
              )}
            </div>

            <div className="flex flex-col gap-2 md:gap-8 md:flex-row justify-between min-h-24 md:min-h-28 pb-4 pl-4">
              <div>
                <h3 className="text-stone-700 font-semibold mb-1">
                  {stepValues.stepTitle}{' '}
                  <span
                    className={clsx('uppercase align-middle text-xs ml-3', {
                      'text-green-700': isVerified,
                      'text-amber-600': !isVerified,
                    })}
                  >
                    {isVerified ? 'Completed' : 'Pending'}
                  </span>
                </h3>
                <p
                  className={clsx('text-stone-500 max-md:text-sm', {
                    'line-clamp-1 text-sm break-all': isIdentityStep && partnerDetails?.name,
                  })}
                >
                  {isIdentityStep && partnerDetails?.name
                    ? `${partnerDetails?.name} • ${partnerDetails?.email}`
                    : stepValues.stepDescription}
                </p>
              </div>
              {!isVerified && (
                <button
                  type="button"
                  disabled={!isPreviousStepVerified}
                  onClick={() => handleCompleteNow(step)}
                  className="bg-green-700 md:mt-3 shrink-0 flex items-center gap-2 text-sm disabled:bg-green-700/30 text-white rounded-xl font-bold h-10 w-fit px-4"
                >
                  Complete Now
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

FirstStep.displayName = 'FirstStep';

export default FirstStep;
