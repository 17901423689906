export const ONBOARDING_PAGE_TYPE = {
  PHONE_VERIFICATION: 'PHONE_VERIFICATION',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  PAN_VERIFICATION: 'PAN_VERIFICATION',
  AADHAAR_VERIFICATION: 'AADHAAR_VERIFICATION',
  BANK_VERIFICATION: 'BANK_VERIFICATION',
  LOGGED_IN: 'LOGGED_IN',
  CONTRACT_SIGNING: 'CONTRACT_SIGNING',
  NEXT_PAGE_INFO_AVAILABLE_AFTER_AUTHENTICATION: 'NEXT_PAGE_INFO_AVAILABLE_AFTER_AUTHENTICATION',
} as const;

export const ONBOARDING_EVENTS = {
  SignUP_Initiated: 'Partner_SignUP_Initiated',
  Phone_Submit: 'Partner_Phone_Submit',
  Phone_OTP_Submit: 'Partner_Phone_OTP_Submit',
  Phone_OTP_Resend: 'Partner_Phone_OTP_Resend',
  Email_Entry: 'Partner_Email_Entry',
  Email_OTP_Submit: 'Partner_Email_OTP_Submit',
  Email_OTP_Resend: 'Partner_Email_OTP_Resend',
  Email_verify: 'Partner_Email_verify',
  KYC_Initiated: 'Partner_KYC_Initiated',
  KYC_PAN_Entry: 'Partner_KYC_PAN_Entry',
  KYC_PAN_Verify: 'Partner_KYC_PAN_Verify',
  KYC_Aadhaar_Entry: 'Partner_KYC_Aadhaar_Entry',
  KYC_Aadhaar_Request_OTP: 'Partner_KYC_Aadhaar_Request_OTP',
  KYC_Aadhaar_OTP_Entry: 'Partner_KYC_Aadhaar_OTP_Entry',
  KYC_Bank_Account_Entry: 'Partner_KYC_Bank_Account_Entry',
  KYC_Bank_Confirm_Account: 'Partner_KYC_Bank_Confirm_Account',
  KYC_Bank_IFSC_Entry: 'Partner_KYC_Bank_IFSC_Entry',
  KYC_TnC_Initiated: 'Partner_KYC_TnC_Initiated',
  KYC_TnC_Success: 'Partner_KYC_TnC_Success',
};

export const AADHAAR_ERROR_MESSAGE =
  'Please send Aadhaar & Bank details to your RM for onboarding.';
