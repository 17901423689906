import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useLayoutStore } from './store';
import TapLogo from '../../../pages/Onboarding/TapLogo';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LogoutIcon from '@mui/icons-material/Logout';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupIcon from '@mui/icons-material/Group';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import { HOME_NAVIGATION_EVENTS } from '../../../pages/Home/constants';
import Lottie from 'react-lottie';
import { useProfile } from '../../../context/ProfileContext';
import newIcon from '../../../assets/lotties/newIcon.json';
import { AccountCircleIcon } from '../../../assets/mui';
interface LeftNavProps {}

const LeftNav: React.FC<LeftNavProps> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: newIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { isLeftNavOpen, setIsLeftOpen } = useLayoutStore(({ isLeftNavOpen, setIsLeftOpen }) => ({
    isLeftNavOpen,
    setIsLeftOpen,
  }));
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { profileData, resetProfileData } = useProfile();

  const logout = () => {
    localStorage.removeItem('token');
    // mixpanel.reset();
    resetProfileData();
    navigate('/login');
  };

  const navList = [
    {
      _id: 1,
      target: '/deals',
      name: `Deals`,
      icon: <HandshakeIcon />,
      active: true,
      enabled: true, // Deals page doesn't have a feature flag, so it's always enabled
    },
    {
      _id: 2,
      target: '/clients',
      name: `Clients`,
      icon: <GroupsIcon />,
      active: false,
      enabled: profileData.features.enableClient,
    },
    {
      _id: 3,
      target: '/family-account',
      name: `Family Account`,
      icon: <GroupsIcon />,
      active: false,
      enabled: profileData.features.enableFamilyAccount,
    },
    {
      _id: 4,
      target: '/investments',
      name: `Investments`,
      icon: <AccountBalanceIcon />,
      active: false,
      enabled: profileData.features.enableInvestment,
    },
    {
      _id: 5,
      target: '/partners',
      name: `Partners`,
      icon: <GroupIcon />,
      active: false,
      enabled: profileData.features.enableMasterPartner,
    },
    {
      _id: 8,
      target: '/profile',
      name: `Profile`,
      icon: <AccountCircleIcon />,
      active: false,
      enabled: profileData.features.enableProfile,
      postIcon: 'new',
    },
    {
      _id: 6,
      target: '/tap-academy',
      name: `Tap Academy`,
      icon: <SchoolIcon />,
      event: HOME_NAVIGATION_EVENTS.Tap_Academy,
      active: false,
      enabled: profileData.features.enableTapAcademy,
    },
    {
      _id: 7,
      target: '/product-resources',
      name: `Resources`,
      icon: <LibraryBooksIcon />,
      event: HOME_NAVIGATION_EVENTS.Products_Deck,
      active: false,
      enabled: profileData.features.enableProductResources,
    },
  ];

  // Filter the navigation items to only include those that are enabled
  const filteredNavList = navList.filter((item) => item.enabled);

  return (
    <>
      <div
        id="mobile-sidebar"
        className={clsx(
          'transition-all flex flex-col duration-300 transform fixed top-0 start-0 bottom-0 z-[70] w-60 bg-black py-8 overflow-y-auto end-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300',
          {
            'translate-x-0': isLeftNavOpen,
            'max-xl:-translate-x-full': !isLeftNavOpen,
          },
        )}
      >
        <div className="px-6">
          <div>
            {profileData?.brandLogoWhite && profileData?.brandLogoWhite !== '' ? (
              <img src={profileData.brandLogoWhite} alt="brand-logo" className="h-8 w-auto" />
            ) : (
              <TapLogo isWhite />
            )}
          </div>

          <div className="mt-10 flex flex-col gap-8">
            {filteredNavList.map((navItem) => (
              <Link
                key={navItem._id}
                to={navItem.target}
                onClick={() => {
                  setIsLeftOpen();
                  if (navItem.event) {
                    mixpanel.track(navItem.event);
                  }
                }}
                className={clsx('text-gray-400 flex items-center gap-2 text-sm font-semibold', {
                  '!text-white': pathname.includes(navItem.target),
                })}
              >
                {navItem.icon}
                {navItem.name}

                {navItem.postIcon && (
                  <div className="relative inline-block">
                    {/* Main Icon */}
                    <div className="relative bg-primary text-black text-xs px-2 py-1 rounded-full">
                      {navItem.postIcon}
                    </div>

                    {/* Lottie Animation */}
                    <div className="absolute top-[-10px] right-[-12px]">
                      <Lottie options={defaultOptions} height={24} width={24} />
                    </div>
                  </div>
                )}
              </Link>
            ))}
          </div>
        </div>
        {profileData?.clientActions?.enableLogout && (
          <div
            className="flex mt-auto cursor-pointer items-center text-white px-6 gap-2 text-xs font-semibold"
            onClick={logout}
          >
            <LogoutIcon fontSize="small" /> Logout
          </div>
        )}
      </div>
      {isLeftNavOpen && (
        <div
          className="flex xl:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-[60]"
          onClick={() => setIsLeftOpen()}
        />
      )}
    </>
  );
};

LeftNav.displayName = 'LeftNav';

export default LeftNav;
