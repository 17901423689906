import React, { useState } from 'react';
import Input from '../../components/Fields/Input';
import { Field } from '../../components/Fields/Field';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NetworkManager } from '../../services';
import { useOnboardingNavigate, useOnboardingStore } from './store';
import { toast } from 'react-hot-toast';
import KYCButton from './KYCButton';
import { ONBOARDING_EVENTS, ONBOARDING_PAGE_TYPE } from './constants';
import { useAnalytics } from '../../hooks/useAnalytics';
import { Phone } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

interface EmailAuthProps {
  isSignupFlow: boolean;
  setIsSignupFlow: (isSignupFlow: boolean) => void;
}

const incorrectOtp = () =>
  toast.error('Invalid OTP', {
    duration: 2500,
    position: 'top-center',
  });

const EmailAuth: React.FC<EmailAuthProps> = ({ isSignupFlow, setIsSignupFlow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const { handleNextPage } = useOnboardingNavigate();
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [timeForOtpResend, setTimeForOtpResend] = useState(30);
  const { track } = useAnalytics();
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const { setNextPage } = useOnboardingStore(({ setNextPage }) => ({
    setNextPage,
  }));
  const schema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email is not valid'),
    otp: yup.string().when(['showOtp'], {
      is: (showOtp: boolean) => showOtp === true,
      then: () =>
        yup
          .string()
          .required('OTP is required')
          .matches(/^\d{4}$/, 'Must be a 4-digit OTP'),
      otherwise: () => yup.string().nullable(),
    }),
    showOtp: yup.boolean().default(false),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: '', otp: '', showOtp: false },
  });

  const email = watch('email');
  const otp = watch('otp');

  const submitEmail = (email: string) => {
    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.emailOTP,
      data: {
        email: email,
      },
    });
  };
  const [searchParams] = useSearchParams();

  const submitOTP = (email: string, otp: number) => {
    const referralCodeValue = searchParams.get('refCode');
    const rmMappingCodeValue = searchParams.get('rmMappingCode');
    const data = {
      email,
      otp,
      ...(referralCodeValue &&
        referralCodeValue !== '' && {
          referralCode: referralCodeValue,
        }),
      ...(rmMappingCodeValue && {
        rmMappingCode: rmMappingCodeValue,
      }),
    };
    return NetworkManager.Call({
      method: 'post',
      path: NetworkManager.PATH.verifyEmailOTP,
      data: data,
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { email, otp } = data;
    setIsLoading(true);

    try {
      if (!showOtpField) {
        // Send OTP
        const emailRes = await submitEmail(email);
        if (emailRes.code === 200) {
          handleEntryEvent(ONBOARDING_EVENTS.Email_verify, { email: email, status: 'success' });
          toast.success('OTP sent successfully');
          setShowOtpField(true);
          setValue('showOtp', true);
          setTimeForOtpResend(30);
          setIsOtpResend(false);
          setIsInputDisabled(true);
        } else {
          handleEntryEvent(ONBOARDING_EVENTS.Email_verify, {
            email: email,
            status: 'failure',
            error: emailRes.error,
          });
          throw new Error(emailRes.error);
        }
      } else {
        // Verify OTP
        const otpRes = await submitOTP(email, otp);
        if (!otpRes.error) {
          handleEntryEvent(ONBOARDING_EVENTS.Email_OTP_Submit, { email: email, status: 'success' });
          if (otpRes.nextPage === ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION) {
            setIsSignupFlow(true);
          }
          handleNextPage(otpRes.nextPage, otpRes.data);
        } else {
          handleEntryEvent(ONBOARDING_EVENTS.Email_OTP_Submit, {
            email: email,
            status: 'failure',
            error: otpRes.error,
          });
          if (otpRes.error && otpRes.error.includes('Invalid OTP')) {
            incorrectOtp();
          } else {
            throw new Error(otpRes.error);
          }
        }
      }
    } catch (error: any) {
      if (error?.response?.status?.toString().startsWith('5')) {
        toast.error('Something went wrong! Please try again', {
          duration: 2500,
          position: 'top-center',
        });
      } else if (error?.response?.status?.toString().startsWith('4')) {
        toast.error(error?.response?.data?.message, {
          duration: 2500,
          position: 'top-center',
        });
      } else {
        toast.error('Something went wrong! Please try again', {
          duration: 2500,
          position: 'top-center',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (timeForOtpResend > 0) {
      const timerId = setTimeout(() => {
        setTimeForOtpResend(timeForOtpResend - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsOtpResend(true);
    }
  }, [timeForOtpResend]);

  const handleEntryEvent = (event: string, obj: object) => {
    track({ eventName: event, eventProperties: obj });
  };

  const handleResend = async () => {
    setIsOtpResend(true);

    try {
      const otpRes = await submitEmail(email);

      if (otpRes?.code?.toString().startsWith('2')) {
        toast.success('OTP sent successfully', {
          duration: 2500,
          position: 'top-center',
        });
      } else if (otpRes.error.includes('Invalid OTP')) {
        incorrectOtp();
      } else {
        toast.error('Something went wrong! Please try again', {
          duration: 2500,
          position: 'top-center',
        });
      }
    } catch (err) {
      if (err?.response?.status?.toString().startsWith('4')) {
        toast.error(
          `${err?.response?.data?.message || 'Something went wrong, please try again later'}`,
          {
            duration: 2500,
            position: 'top-center',
          },
        );
      } else {
        toast.error('Something went wrong! Please try again', {
          duration: 2500,
          position: 'top-center',
        });
      }
    } finally {
      setIsOtpResend(false);
      setTimeForOtpResend(30);
    }
  };

  const handleChangeEmail = () => {
    setShowOtpField(false);
    setValue('otp', '');
    setValue('showOtp', false);
    setIsInputDisabled(false);
  };

  return (
    <div className="flex flex-col gap-8">
      <div>
        <h2 className="text-2xl font-semibold mb-2">Get started</h2>
        <p className="text-gray-600">Welcome to Tap Partner!</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <Field label="Email Address" error={errors.email?.message}>
          <Input
            type="email"
            placeholder="Enter your email address"
            disabled={isInputDisabled}
            {...register('email')}
          />
        </Field>

        {showOtpField && (
          <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center">
              <label className="text-sm text-gray-600">OTP</label>
              {timeForOtpResend > 0 ? (
                <span className="text-sm text-gray-500">Resend OTP in {timeForOtpResend}s</span>
              ) : (
                <button
                  type="button"
                  onClick={handleResend}
                  disabled={isLoading || !isOtpResend}
                  className="text-sm text-green-800 hover:underline"
                >
                  Resend OTP
                </button>
              )}
            </div>
            <Input
              type="text"
              numericOnly={true}
              placeholder="Enter 4-digit OTP"
              maxLength={4}
              {...register('otp')}
            />
            {errors.otp?.message && (
              <span className="text-red-500 text-sm">{errors.otp.message}</span>
            )}
          </div>
        )}

        <KYCButton
          isLoading={isLoading}
          text={!showOtpField ? 'Get OTP' : 'Verify & Continue'}
          disabled={
            !email ||
            !email.includes('@') ||
            !email.includes('.') ||
            (showOtpField && (!otp || otp.length !== 4))
          }
          className="!mt-4"
        />
      </form>
      {!isSignupFlow && (
        <>
          <div className="flex items-center gap-4">
            <div className="h-[1px] flex-1 bg-gray-200" />
            <span className="text-gray-400">or</span>
            <div className="h-[1px] flex-1 bg-gray-200" />
          </div>

          <button
            type="button"
            onClick={() => {
              setNextPage(ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION);
            }}
            className="flex items-center justify-center gap-2 w-full py-3 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50"
          >
            <Phone className="w-4 h-4" />
            Login via Phone
          </button>
        </>
      )}
      {showOtpField && (
        <div className="flex flex-col items-center gap-2">
          <button
            type="button"
            onClick={handleChangeEmail}
            className="text-sm text-green-800 hover:underline"
          >
            Change Email Address
          </button>
        </div>
      )}
    </div>
  );
};

EmailAuth.displayName = 'EmailAuth';

export default EmailAuth;
