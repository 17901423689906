import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface FooterProps {}

const supportEmail = 'mailto:support@tapinvest.in';

const whatsapp = 'https://wa.me/916362667523';

const linkedin = 'https://www.linkedin.com/company/leafround/';

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="bg-green-950 py-10 md:pt-16 md:pb-20">
      <div className="max-w-screen-2xl mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-3 justify-between items-start md:items-center">
          <div>
            <div className="mb-1 md:mb-3 flex items-center gap-2.5">
              <h3 className="text-white text-3xl lg:text-5xl font-medium">Tap to get started</h3>
              <ArrowForwardIcon className="text-white md:!text-4xl" />
            </div>
            <p className="text-white/50 md:text-lg">Get started with as low as ₹50,000</p>
          </div>

          <Link to="/login">
            <button className="bg-white text-slate-600 text-sm font-semibold px-7 rounded-xl h-10">
              Get started today
            </button>
          </Link>
        </div>
        <hr className="border-t border-white/20 mt-12" />
        <div className="mt-6 flex flex-col md:flex-row gap-8 lg:gap-16 xl:gap-20">
          <div className="flex flex-col gap-2 text-lg text-white/50 font-semibold">
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/risk-disclosure">Risk Disclosure</Link>
          </div>
          {/* <div>
            <h3 className="text-white text-lg font-semibold mb-2">Register As</h3>
            <p className="text-lg flex flex-col text-white/50 font-semibold">
              <a href="https://forms.gle/oH3RvEoeS4KhCfEQ7" target="_blank" rel="noreferrer">
                Non Individual Signup
              </a>
            </p>
          </div> */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-2">Address</h3>
            <p className="text-lg flex flex-col text-white/50 font-semibold">
              <span>Tap Invest</span>
              <span>Sector 2, HSR Layout</span>
              <span>Bengaluru</span>
            </p>
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold mb-2">Contact Us</h3>
            <p className="text-lg flex flex-col text-white/50 font-semibold">
              <a href={supportEmail} target="_blank" rel="noreferrer">
                via Email
              </a>
              <a href={whatsapp} target="_blank" rel="noreferrer">
                via Whatsapp
              </a>
              <a href={linkedin} target="_blank" rel="noreferrer">
                via LinkedIn
              </a>
              <Link to="https://app.tapinvest.in" target="_blank">
                Visit Tap Invest
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.displayName = 'Footer';

export default Footer;
