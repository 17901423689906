import React, { useMemo, useState } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link, useParams } from 'react-router-dom';
import { useSubPartnerInvestments, useSubPartners } from './usePartnersDetails';
import { getRupee } from '../../utils/numbers';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import Skeleton from '@mui/material/Skeleton';
import ClientInvestmentsTable from './PartnersInvestmentsTable';
import TableSkeleton from '../../components/TableSkeleton';
import { useDelay } from '../../hooks/useDelay';
import { debounce } from '../../utils/debounce';
import { SearchOutlined } from '@mui/icons-material';
import Input from '../../components/Fields/Input';
import { NetworkManager } from '../../services';
import { useDownloadCsv } from '../../hooks/useDownloadCsv';
import { PARTNER_DETAIL_EVENTS } from './constants';
import { useAnalytics } from '../../hooks/useAnalytics';
import { MONTHS } from '../Investments/constants';

interface PartnersDetailProps {}

const PartnersDetail: React.FC<PartnersDetailProps> = () => {
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [search, setSearch] = useState('');
  const { partnerId } = useParams();
  // const { data, isLoading: dataLoading } = useClientDetails(partnerId);

  const { data, isLoading: dataLoading } = useSubPartners({
    payload: {
      id: partnerId,
      pageNo,
      pageSize,
      orderBy,
      order,
      search,
    },
  });

  const { data: investmentsData, isLoading: investmentsLoading } = useSubPartnerInvestments({
    id: partnerId,
    pageNo,
    pageSize,
    orderBy,
    order,
    search,
  });

  const { track } = useAnalytics();

  const isReady = useDelay(dataLoading, 200);

  const isInvestmentsReady = useDelay(investmentsLoading, 500);

  const { email, firstName, lastName, pan, phone } = data?.data?.elements[0]?.profile || {};

  const { totalInvested, totalEarned, currentMonthEarnedIncome, month } =
    data?.data?.elements[0]?.partnerAggregate || {};

  let name = firstName || lastName ? firstName + ' ' + lastName : '';

  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleChangeSort = (e, columnId) => {
    if (columnId === orderBy) {
      setOrder(order === 'desc' ? 'asc' : 'desc');
    }
    setOrderBy(columnId);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debounceHandleSearch = debounce(handleSearch, 500);

  const currentMonth = useMemo(() => MONTHS[month - 1], [month]);

  return (
    <div className="my-6 max-w-7xl mx-auto">
      {/* Heading */}
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-4">
          <Link
            to="/partners"
            onClick={() =>
              track({
                eventName: PARTNER_DETAIL_EVENTS.Partner_Overview,
              })
            }
            className="bg-green-700/10 flex items-center justify-center p-1.5 h-9 w-9 rounded-full text-green-700"
          >
            <ArrowBack fontSize="small" />
          </Link>
          <h1 className="text-lg md:text-xl font-semibold">Partners Overview</h1>
        </div>
      </div>

      {/* User Details */}
      <div className="bg-black/[0.04] min-h-20 flex items-center gap-6 sm:gap-3 lg:gap-2 rounded-lg pl-6 pr-10 py-4 mt-6">
        {/* Name/PAN */}
        <div className="w-full max-lg:mb-6 lg:w-1/3 flex items-center gap-3">
          {name && (
            <Avatar
              sizes=""
              className="uppercase md:!w-14 md:!h-14 !bg-white/80 !text-slate-600 border border-slate-300"
            >
              {name?.charAt(0)}
            </Avatar>
          )}
          <div>
            <h3 className="text-slate-800 mb-0.5 font-medium">
              {isReady ? name : <Skeleton width={50} />}
            </h3>
            <h4 className="text-stone-500 text-sm font-medium tracking-widest">{pan}</h4>
          </div>
        </div>
        {/* Email ID */}
        <div className="w-full lg:w-1/3 lg:text-right">
          <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Email ID</h3>
          <h4 className="text-sm font-medium text-slate-800 break-all">
            {isReady ? email || '-' : <Skeleton width={70} className="ml-auto" />}
          </h4>
        </div>
        {/* Phone */}
        <div className="w-full lg:w-1/3 lg:text-right">
          <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">Phone No.</h3>
          <h4 className="text-sm font-medium text-slate-800">
            {isReady ? phone || '-' : <Skeleton width={70} className="ml-auto" />}
          </h4>
        </div>
      </div>

      {/* Investment Summary */}
      <div className="mt-6 mb-8 md:mb-12 grid grid-cols-2 xl:grid-cols-3 border border-stone-300 rounded-lg">
        <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs md:uppercase mb-1">Total Invested</h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700 tracking-wider">
            {isReady ? getRupee.format(totalInvested || 0) : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6 xl:border-r xl:border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs md:uppercase mb-1">Total Earnings</h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700">
            {isReady ? getRupee.format(totalEarned || 0) : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6 border-r border-r-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs md:uppercase mb-1">
            {currentMonth} Earnings
          </h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700">
            {isReady ? getRupee.format(currentMonthEarnedIncome || 0) : <Skeleton width={60} />}
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-start gap-3 items-start md:flex-row md:justify-between md:items-center mt-6 mb-4">
        <Input
          prefixIcon={<SearchOutlined />}
          placeholder="Search"
          onChange={debounceHandleSearch}
          className="ps-11"
        />
        {/* <div className="flex gap-2 items-center">
                    <button
                        onClick={() => {
                            downloadCsv();
                            track({
                                eventName:
                                    CLIENT_DETAIL_EVENTS.Client_Overview_Download_Excel,
                            });
                        }}
                        disabled={csvLoading}
                        type="button"
                        className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
                    >
                        <DownloadForOfflineOutlinedIcon fontSize="small" />
                        Download Excel
                    </button>
                </div> */}
      </div>
      {/* Client Investments Table */}
      {isInvestmentsReady && !investmentsLoading ? (
        <ClientInvestmentsTable
          data={investmentsData?.data}
          pageSize={pageSize}
          pageNo={pageNo}
          orderBy={orderBy}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangeSort={handleChangeSort}
        />
      ) : (
        <TableSkeleton />
      )}

      {/* Bank Details */}
      <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={open}>
        <div className="p-5 border bg-white border-zinc-200 rounded-lg">
          {dataLoading ? (
            <Skeleton width={80} height={40} />
          ) : (
            <h3 className="text-lg font-semibold mb-4">Bank Details</h3>
          )}
          <div>
            <div>
              <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                Account number
              </h3>
              <p className="text-sm font-medium text-stone-700">{/* {accountNumber || "-"} */}</p>
            </div>

            <div className="mt-5">
              <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">IFSC Code</h3>
              <p className="text-sm font-medium text-stone-700">{/* {ifscCode || "-"} */}</p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

PartnersDetail.displayName = 'PartnersDetail';

export default PartnersDetail;
