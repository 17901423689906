import * as R from 'ramda';
import { formatDate } from '../../utils/date';
import { getRupee, twoDecimalNumber } from '../../utils/numbers';
import { getInvestmentStatusTag } from '../utils/investment';
import DealNameAndStatus from '../../components/organisms/Tables/CellRenderer/DealNameAndStatus';

function createData(data) {
  if (!data) {
    return {
      companyName: '',
      state: '',
      status: '',
      investedOn: '',
      amount: '',
      maturityDate: '',
      payoutDate: '',
      irr: '',
      payoutAmount: '',
      isReinvestInvestment: false,
    };
  }

  const {
    companyName = '',
    state = '',
    status = '',
    investedOn = '',
    amount = '',
    maturityDate = '',
    payoutDate = '',
    irr = '',
    payoutAmount = '',
    isReinvestInvestment = false,
  } = data;

  return {
    dealName: (
      <DealNameAndStatus
        name={companyName}
        status={status}
        isReinvestInvestment={isReinvestInvestment}
        dealData={data}
      />
    ),
    investedOn: investedOn ? formatDate(investedOn) : '-',
    amount: (
      <div className="flex items-center gap-1">
        {getRupee.format(amount)} {getInvestmentStatusTag(state)}
      </div>
    ),
    maturityDate: maturityDate ? formatDate(maturityDate) : '-',
    irr: `${irr ? twoDecimalNumber(irr) + '%' : '-'}`,
    payoutDate,
    payoutAmount: payoutAmount ? getRupee.format(payoutAmount) : '-',
  };
}

export const getRowsAndCols = (data) => {
  const rows = [];
  const data_row = R.path(['items'], data);

  data_row?.forEach((element) => {
    rows.push(createData(element));
  });

  return rows;
};
