import React, { useState } from 'react';
import YouTube from 'react-youtube';
import LoadingDots from '../../components/molecules/LoadingDotsProps';

interface TapAcademyProps {}

const academyVideos = [
  {
    id: 'a4qZ6LH1PQU',
  },
  {
    id: 'OUXLtfSVqoM',
  },
  {
    id: 'wDrvsBqZH68',
  },
];

const webinarVideos = [
  {
    id: 'xEXIGhjH3YA',
  },
  {
    id: 'i24McplQ-k8',
  },
  {
    id: 'EVpVKsIQ1rc',
  },
];

const AcademyCard = ({ videoId }) => {
  const [loading, setLoading] = useState(true);

  const handleVideoReady = () => {
    setLoading(false); // When the video is ready, hide the loading indicator
  };

  return (
    <div className="rounded p-4 border border-stone-200 bg-white overflow-hidden">
      {loading && (
        <div className="flex justify-center items-center h-[340px]">
          Loading <LoadingDots />
        </div>
      )}
      <YouTube
        videoId={videoId}
        opts={{
          width: '100%',
          height: '340',
        }}
        onReady={handleVideoReady}
        className={loading ? 'hidden' : ''}
      />
    </div>
  );
};

const TapAcademy: React.FC<TapAcademyProps> = () => {
  return (
    <div className="flex flex-col max-w-7xl mx-auto mb-6">
      <h2 className="font-medium text-4xl mb-9 mt-6">Tap Academy</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
        {academyVideos?.map((video) => (
          <AcademyCard videoId={video.id} />
        ))}
      </div>
      <h2 className="font-medium text-4xl mb-9 mt-6">Webinars</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
        {webinarVideos?.map((video) => (
          <AcademyCard videoId={video.id} />
        ))}
      </div>
    </div>
  );
};

TapAcademy.displayName = 'TapAcademy';

export default TapAcademy;
