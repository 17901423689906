import { useQuery, PATH, Call } from '../../services/NetworkManager';

export function getDealDetails({ queryKey }) {
  const id = queryKey[0].split(',')[1];
  const financeType = queryKey[0].split(',')[2];

  return Call({
    method: 'get',
    path: `${PATH.deal.replace('{{dealId}}', id)}${
      financeType ? `?financeType=${financeType}` : ''
    }`,
  });
}

export function getDealStats({ queryKey }) {
  const id = queryKey[0].split(',')[1];

  return Call({
    method: 'get',
    path: PATH.dealInfo.replace('{{dealId}}', id),
  });
}

export function getDealClients({ queryKey }) {
  const pageNo = queryKey[0].split(',')[1];

  return Call({
    method: 'post',
    path: PATH.clients,
    data: {
      currentPage: parseInt(pageNo),
      pageSize: 100,
      sortBy: 'walletBalance',
      sortDir: 'DESC',
      searchOn: ['name', 'email', 'pan', 'phone'],
    },
  });
}

// TODO: Deal Details types once API keys available
export function useDealDetails(dealId?: string, financeType?: string) {
  const { data, isLoading } = useQuery(`${['deal', dealId, financeType]}`, getDealDetails);
  return { data, isLoading };
}

export function useDealStats(dealId?: string, isPooling?: boolean) {
  const { data, isLoading } = useQuery(`${['dealInfo', dealId]}`, getDealStats, {
    enabled: !isPooling,
  });
  return { data, isLoading };
}

export function useDealClients(pageNo: number, value?: string) {
  const { data, isLoading, refetch } = useQuery(
    `${['dealClients', pageNo, value]}`,
    getDealClients,
  );
  return { data, isLoading, refetch };
}

export function getMetalTransaction() {
  return Call({
    method: 'get',
    path: PATH.metalTransaction,
  });
}

export function useMetalTransaction() {
  const { data, isLoading, refetch } = useQuery('metalTransaction', getMetalTransaction);
  return { data, isLoading, refetch };
}

export function getPartnerMetalCommission({ amount, metal }) {
  return Call({
    method: 'get',
    path: PATH.partnerMetalCommission.replace('{{amount}}', amount).replace('{{metal}}', metal),
  });
}


export function getMetalLivePrice() {
  return Call({
    method: 'get',
    path: PATH.metalLivePrice,
  });
}


export function getHistoricalMetalData({ from, to, range }) {
  return Call({
    method: 'get',
    path: PATH.historicalMetalData
      .replace('{{from}}', from)
      .replace('{{to}}', to)
      .replace('{{range}}', range),
  });
}


export function postSellMetal({ price, blockId, metalType, quantity, amount, clientPublicId }) {
  return Call({
    method: 'post',
    path: PATH.sellMetal,
    data: {
      price,
      blockId,
      metalType,
      quantity,
      amount,
      clientPublicId,
    },
  });
}


export function generateMetalPaymentLink({
  clientPublicId,
  price,
  blockId,
  quantity,
  amount,
  metal,
  amountPayable,
}) {
  return Call({
    method: 'post',
    path: PATH.generateUserMetalPaymentLink,
    data: {
      clientPublicId,
      price,
      blockId,
      quantity,
      amount,
      metal,
      amountPayable,
    },
  });
}

export const downloadTransactionInvoice = (invoiceId) => {
  return Call({
    method: 'get',
    path: PATH.downloadMetalInvoice.replace('{{invoiceId}}', invoiceId),
  });
};
