import { useLayoutEffect } from 'react';

export function useTokenMessageHandler(onTokenReceived) {
  const allowedOrigins = [
    'https://partnerqa.fundsindia.com',
    'https://partnerneo.fundsindia.com',
    'https://leafround-post-message.vercel.app',
    'https://www.partner.fundsindia.com',
    'https://partner.fundsindia.com',
    'https://stagingpartner.fundsindia.com',
    'https://staging.fundsindia.com',
  ];

  useLayoutEffect(() => {
    const handleMessage = (event) => {
    
      if (!allowedOrigins.includes(event.origin)) {
        return;
      }
      // ✅ Handle "AUTH_TOKEN"
      if (event.data?.type === 'AUTH_TOKEN' && event.data?.token) {
        onTokenReceived(event.data.token, event.data.pt); // Directly pass the token
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [allowedOrigins, onTokenReceived]);
}
