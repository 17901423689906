import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useRef } from 'react';
import {
  InsertDriveFileOutlinedIcon,
  CloseOutlinedIcon,
  FileUploadOutlinedIcon
} from '../../../../assets/mui';
import toast from 'react-hot-toast';
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';

const AddBulkUser = ({ onClose, open, onConfirm, selectedFile, setSelectedFile, usersText }) => {
  const fileInputRef = useRef(null); // Ref for file input

  const requiredFields = [];

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && validateFile(file)) {
      setSelectedFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && validateFile(file)) {
      setSelectedFile(file);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const validateFile = (file) => {
    const allowedTypes = [
      // 'application/vnd.ms-excel',
      'text/csv',
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const maxFileSize = 320 * 1024;
    return allowedTypes.includes(file.type) && file.size <= maxFileSize;
  };

  const handleConfirm = () => {
    if (selectedFile) {
      parseAndValidateCSV(selectedFile);
    } else {
      toast.error('Please select a file before confirming.');
    }
  };

  const parseAndValidateCSV = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const { data } = result;
        const updatedData = validateCSVData(data);
        // Check if there are any rows with errors
        const hasErrors = updatedData.some((row) => row.Status === 'FAILURE');

        if (hasErrors) {
          // Convert updated data back to CSV format with errors
          const csvContent = Papa.unparse(updatedData);
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          saveAs(blob, 'Updated_Users_File_With_Errors.csv');
          setSelectedFile(null);
          fileInputRef.current.value = ''; // Reset the file input
          // Show a message to the user
          toast.error(
            'There are errors in the CSV file. Please check the downloaded file for details.',
          );
        } else {
          // No errors, proceed with confirmation
          onConfirm(updatedData);
          onClose();
        }
      },
      error: () => {
        toast.error('Error parsing CSV file.');
      },
    });
  };

  const validateCSVData = (data) => {
    const errors = [];

    // Check if data array is within size limits
    if (data.length === 0) {
      errors.push('CSV must contain at least 1 row of data.');
      return errors;
    }
    if (data.length > 100) {
      errors.push('CSV cannot contain more than 100 rows of data.');
      return errors;
    }

    // Validation regex patterns
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10,}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const accountNumberRegex = /^\d{9,18}$/;
    const aadharNumberRegex = /^\d{4}$/;
    const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const pinCodeRegex = /^\d{6}$/;

    data.forEach((row, index) => {
      let status = 'Valid';
      let remarks = '';

      // Check if either publicId exists OR both email and phone exist
      const hasPublicId = row.publicId && row.publicId.trim() !== '';
      const hasEmail = row.email && row.email.trim() !== '';
      const hasPhone = row.phone && row.phone.trim() !== '';
      // const hasPan = row.pan && row.pan.trim() !== '';

      if (!hasPublicId && !(hasEmail && hasPhone)) {
        status = 'FAILURE';
        remarks += 'Must have either publicId OR email and phone.';
      }

      // Validate email format if it exists
      if (hasEmail && !emailRegex.test(row.email.trim())) {
        status = 'FAILURE';
        remarks += `Invalid email format - "${row.email}". `;
      }

      // Validate phone format if it exists
      if (hasPhone && !phoneRegex.test(row.phone.trim())) {
        status = 'FAILURE';
        remarks += `Invalid phone format - "${row.phone}". `;
      }

      // Validate PAN format if it exists
      if (row.pan && row.pan.trim() && !panRegex.test(row.pan.trim())) {
        status = 'FAILURE';
        remarks += `Invalid PAN format - "${row.pan}". `;
      }

      // Validate Account Number format if it exists
      if (
        row.accountNumber &&
        row.accountNumber.trim() &&
        !accountNumberRegex.test(row.accountNumber.trim())
      ) {
        status = 'FAILURE';
        remarks += `Invalid Account Number format - "${row.accountNumber}". `;
      }

      // Validate Aadhar Number format if it exists
      if (
        row.aadhaarLast4Digit &&
        row.aadhaarLast4Digit.trim() &&
        !aadharNumberRegex.test(row.aadhaarLast4Digit.trim())
      ) {
        status = 'FAILURE';
        remarks += `Invalid Aadhar Number format - "${row.aadhaarLast4Digit}". `;
      }

      // Validate IFSC Code format if it exists
      if (row.ifsc && row.ifsc.trim() && !ifscCodeRegex.test(row.ifsc.trim())) {
        status = 'FAILURE';
        remarks += `Invalid IFSC Code format - "${row.ifsc}". `;
      }

      // Validate Pin Code format if it exists
      if (
        row.addressPincode &&
        row.addressPincode.trim() &&
        !pinCodeRegex.test(row.addressPincode.trim())
      ) {
        status = 'FAILURE';
        remarks += `Invalid Pin Code format - "${row.addressPincode}". `;
      }

      // Check other required fields if they exist in requiredFields array
      requiredFields.forEach((field) => {
        if (field !== 'publicId' && field !== 'email' && field !== 'phone') {
          if (!row[field] || row[field].trim() === '') {
            status = 'FAILURE';
            remarks += `Missing value for required field "${field}". `;
          }
        }
      });

      // Add Status and Remarks to each row
      row.Status = status;
      row.Remarks = remarks.trim();
    });

    return data;
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setSelectedFile(null);
    fileInputRef.current.value = ''; // Reset the file input
  };
  const handleOpenTemplatePDF = () => {
    const pdfPath = 'https://cdn.leafround.com/partner_template.csv';
    window.open(pdfPath, '_blank');
  };
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: { borderRadius: '12px !important' } }}
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-semibold">Add bulk {usersText}</h3>
            <p className="text-slate-600 text-sm mt-1.5">
              <span className="text-slate-700">NOTE:</span> Once the upload starts it can't be
              canceled
            </p>
          </div>
          <CloseOutlinedIcon
            className="cursor-pointer bg-slate-200 rounded-full p-1"
            onClick={() => {
              onClose();
            }}
            style={{
              width: '28px',
              height: '28px',
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={`mt-4 flex justify-center items-center border-dashed border-2 rounded-lg h-40 cursor-pointer ${
            selectedFile ? 'border-green-500' : 'border-gray-300'
          }`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={() => fileInputRef.current.click()}
        >
          <input
            type="file"
            id="fileInput"
            accept=".csv"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
          {!selectedFile ? (
            <div className="flex flex-col items-center text-gray-500">
              <div className="!bg-white p-1 rounded-lg">
                <FileUploadOutlinedIcon style={{ fontSize: '24px', color: '#6b7280' }} />
              </div>
              <p className="mt-2 text-sm font-normal">
                <span className="font-semibold"> Click to upload </span>or drag and drop
              </p>
              <p className="text-xs">Upload CSV file only (max. 320kbps)</p>
            </div>
          ) : (
            <div className="flex flex-col items-center text-gray-700">
              <InsertDriveFileOutlinedIcon style={{ fontSize: '24px' }} />
              <p className="mt-2 text-sm font-medium">
                {selectedFile.name && selectedFile.name.length > 25
                  ? selectedFile.name.slice(0, 20) + '...'
                  : selectedFile.name}
              </p>
              <p className="text-xs">{(selectedFile.size / 1000).toFixed(1)} KB</p>
              <button
                onClick={handleDelete}
                className="text-red-600 text-xs mt-1 underline hover:text-red-800"
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-between mt-6">
          <button
            onClick={handleOpenTemplatePDF}
            className="flex items-center gap-2 text-sm border bg-white rounded-xl font-bold h-10 w-fit px-4"
            type="button"
          >
            Download Template
          </button>
          <div className=" flex justify-end gap-4">
            <button
              onClick={onClose}
              className="flex items-center gap-2 text-sm border bg-white rounded-xl font-bold h-10 w-fit px-4"
              type="button"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="bg-green-700 flex items-center gap-2 text-sm text-white rounded-xl font-bold h-10 w-fit px-4 disabled:bg-green-700/60"
              type="button"
              disabled={!selectedFile}
            >
              Confirm
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddBulkUser;
