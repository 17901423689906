import { NetworkManager } from '../services';

export function getProfileInvoices() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getProfileInvoices,
    retry: 3,
  });
}

export function downloadProfileInvoices({ invoiceId }) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.downloadProfileInvoice.replace('{{invoiceId}}', invoiceId),
    retry: 3,
  });
}
