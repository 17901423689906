export const numDifferentiation = (num: number) => {
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'long',
  });
  // Format the number using the formatter
  let formatted = formatter.format(num);

  // Replace the compact notation symbol for a thousand from "T" to "K"
  formatted = formatted.replace(/T$/, 'K');

  return formatted;
};

export const twoDecimalNumber = (num: number) => {
  const roundedNumber = Math.round(num * 100) / 100; // Round to two decimal places
  return roundedNumber.toFixed(2); // Ensure two decimal places
};

export const twoDecimalNumberWithoutRounding = (num: number): number => {
  if (isNaN(num)) return 0; // Handle invalid input
  const numStr = num.toString(); // Convert the number to a string
  const [whole, decimal] = numStr.split('.'); // Split at the decimal point

  // If there's no decimal part, return the whole number as a number
  if (!decimal) return Number(whole);

  // Truncate the decimal to two places and return the number
  return Number(`${whole}.${decimal.substring(0, 2)}`);
};
export const fourDecimalNumberWithoutRounding = (num: number): number => {
  if (isNaN(num)) return 0; // Handle invalid input
  const numStr = num.toString(); // Convert the number to a string
  const [whole, decimal] = numStr.split('.'); // Split at the decimal point

  // If there's no decimal part, return the whole number as a number
  if (!decimal) return Number(whole);

  // Truncate the decimal to two places and return the number
  return Number(`${whole}.${decimal.substring(0, 4)}`);
};

const roundUp = (value, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.ceil(value * factor) / factor;
};

export const fiveDecimalNumberWithoutRounding = (num: number): number => {
  if (isNaN(num)) return 0; // Handle invalid input
  const numStr = num.toString(); // Convert the number to a string
  const [whole, decimal] = numStr.split('.'); // Split at the decimal point

  // If there's no decimal part, return the whole number as a number
  if (!decimal) return Number(whole);

  // Truncate the decimal to two places and return the number
  const truncatedNumber = Number(`${whole}.${decimal.substring(0, 5)}`);
  return roundUp(truncatedNumber, 4);
};

export const getRupee = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  maximumFractionDigits: 2,
  currency: 'INR',
});

export const getRupeeWithDecimal = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});
