import NameAndKYC from '../../components/organisms/Tables/CellRenderer/NameAndKYC';
import NameAndPAN from '../../components/organisms/Tables/CellRenderer/NameAndPAN';
import CurrencyAmount from '../../components/organisms/Tables/CellRenderer/CurrencyAmount';

import * as R from 'ramda';
import Name from '../../components/organisms/Tables/CellRenderer/Name';

function createData(
  name,
  amount,
  balance,
  email,
  phone,
  clientPublicId,
  url,
  isKycVerified,
  pan,
  memberLevel,
  originalName,
  originalEmail,
  familyName,
) {
  return {
    name,
    amount,
    balance,
    email,
    phone,
    id: clientPublicId,
    url: `/clients/${clientPublicId}`,
    isKycVerified,
    pan,
    memberLevel,
    clientPublicId,
    originalName,
    originalEmail,
    familyName,
  };
}

export const getRowsAndCols = (data, type) => {
  const rows = [];
  const data_row = R.path(['items'], data);

  data_row?.forEach((element) => {
    const name = element.isKycVerified ? (
      <NameAndPAN
        name={element.name}
        pan={element.pan}
        memberLevel={element.memberLevel}
        type={type}
        familyName={element.familyName}
      />
    ) : (
      {
        value: (
          <NameAndKYC
            name={element.name}
            memberLevel={element.memberLevel}
            type={type}
            familyName={element.familyName}
          />
        ),
        kyc: false,
      }
    );

    rows.push(
      createData(
        name,
        <CurrencyAmount amount={element.activeInvested} />,
        <CurrencyAmount amount={element.balance} />,
        <Name name={element.email} />,
        <Name name={element.phone} />,
        element.clientPublicId,
        element.url,
        element.isKycVerified,
        element.pan,
        element.name,
        element.email,
        element.memberLevel,
        element.familyName,
      ),
    );
  });

  return rows;
};
